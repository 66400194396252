import { IPricePackage } from 'src/app/interfaces/price-package.interface';

/**
 * Filters pricePackages and returns the one with the requested Id.
 * @param {IPricePackage[]} pricePackages all the price packages
 * @param {number} Id the requested Id
 * @returns {IPricePackage | undefined} the filtered pricePackages
 */
export function filterPackagesById(
  pricePackages: IPricePackage[],
  Id: number,
): IPricePackage | undefined {
  const filteredPackage = pricePackages.find((i) => i.id === Id);
  return filteredPackage;
}

<div *ngIf="property" class="single-property">
  <div *ngIf="property.isDeleted" class="deleted-tag">
    {{ "PROPERTY_DELETED" | translate }}
  </div>
  <div class="grouped-sections" (click)="navigateToEdit()">
    <div class="section-1">
      <ng-container *ngIf="property.photos && property.photos.length > 0">
        <img
          src="{{
            api +
              '/property/getImage/' +
              property.photosPath +
              '/' +
              property.photos[0].name
          }}"
          alt=""
        />
      </ng-container>
      <ng-container *ngIf="!property.photos || property.photos.length === 0">
        <img src="assets/images/propertyPlaceholder.png" alt="" />
      </ng-container>
    </div>
    <div class="section-2">
      <div class="top">
        <div class="subsection address">
          <ng-container *ngIf="property.address">
            {{ property.address }}
          </ng-container>
          <ng-container *ngIf="!property.address"> - </ng-container>
        </div>
        <div class="subsection rent-sale">
          <div class="rent-sale-text">
            <ng-container *ngIf="property.action === Action.Rent">
              {{ "ACTION_0" | translate }}
            </ng-container>
            <ng-container *ngIf="property.action === Action.Sale">
              {{ "ACTION_1" | translate }}
            </ng-container>
            <ng-container *ngIf="!property.action"> - </ng-container>
          </div>
          <div class="rent-sale-price">
            <ng-container *ngIf="property.price">
              {{ property.price }}
            </ng-container>
            <ng-container *ngIf="!property.price"> - </ng-container>
            €
          </div>
        </div>
      </div>
      <div class="middle">
        <div class="price">{{ property.price }}€</div>
        <div class="subsection rent-sale">
          <ng-container *ngIf="property.action === Action.Rent">
            {{ "ACTION_0" | translate }}
          </ng-container>
          <ng-container *ngIf="property.action === Action.Sale">
            {{ "ACTION_1" | translate }}
          </ng-container>
          <ng-container *ngIf="!property.action"> - </ng-container>
        </div>
      </div>
      <div class="bottom" [ngClass]="{ land: property.type === Type.Land }">
        <div class="subsection" *ngIf="property.type !== Type.Land">
          <div class="icon">
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#icons-house-rooms'"
              [width]="'20px'"
              [height]="'20px'"
            ></svg-icon-sprite>
          </div>
          <div class="value">
            {{ property.rooms }}
          </div>
          <div class="label complete">
            {{ "SINGLE_PROPERTY_ROOMS" | translate }}
          </div>
          <div class="label truncated">
            {{ "SINGLE_PROPERTY_ROOMS_TRUNCATED" | translate }}
          </div>
        </div>
        <div class="subsection" *ngIf="property.type !== Type.Land">
          <div class="icon">
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#icons-house-floors'"
              [width]="'20px'"
              [height]="'20px'"
            ></svg-icon-sprite>
          </div>
          <div class="value">
            <ng-container *ngIf="property.floor">
              <span
                class="pre-label"
                [ngClass]="{
                  'single-label': property.floor < 4 || property.floor > 15,
                }"
                >{{ "FLOOR_PURE_" + property.floor | translate }}</span
              >
              <span
                class="label complete"
                *ngIf="property.floor >= 4 && property.floor <= 15"
              >
                {{ "FLOOR_PURE" | translate }}
              </span>
              <span
                class="label truncated"
                *ngIf="property.floor >= 4 && property.floor <= 15"
              >
                {{ "FLOOR_PURE_TRUNCATED" | translate }}
              </span>
            </ng-container>
            <ng-container *ngIf="!property.floor"> - </ng-container>
          </div>
        </div>
        <div class="subsection" *ngIf="property.type === Type.Land">
          <div class="icon">
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#outdoor'"
              [width]="'20px'"
              [height]="'20px'"
            ></svg-icon-sprite>
          </div>
          <div class="label">
            <ng-container *ngIf="property.subType === SubType.Plot">
              {{ "SUBTYPE_12" | translate }}
            </ng-container>
            <ng-container *ngIf="property.subType === SubType.Patch">
              {{ "SUBTYPE_13" | translate }}
            </ng-container>
            <ng-container *ngIf="property.subType === SubType.Island">
              {{ "SUBTYPE_14" | translate }}
            </ng-container>
            <ng-container *ngIf="property.subType === SubType.Other_Land">
              {{ "SUBTYPE_15" | translate }}
            </ng-container>
          </div>
        </div>
        <div class="subsection">
          <div class="icon">
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#icons-house-meters'"
              [width]="'20px'"
              [height]="'20px'"
            ></svg-icon-sprite>
          </div>
          <div class="value">
            <ng-container *ngIf="property.size">
              {{ property.size }}
            </ng-container>
            <ng-container *ngIf="!property.size"> - </ng-container>
          </div>
          <div class="label">{{ "SIZE_M2" | translate }}</div>
        </div>
      </div>
      <div
        class="links"
        *ngIf="property.publishState === PublishState.Unpublished"
      >
        {{ "SINGLE_PROPERTY_PLATFORMS_UNPUBLISHED" | translate }}
      </div>
      <div
        class="links links-pending"
        *ngIf="property.publishState === PublishState.Pending"
      >
        {{ "SINGLE_PROPERTY_PLATFORMS_PENDING" | translate }}
      </div>
      <div
        class="links"
        *ngIf="
          property.publishState === PublishState.Published &&
          property.pricePackage &&
          property.pricePackage.platforms &&
          property.pricePackage.platforms.length > 0
        "
      >
        <div class="text">{{ "SINGLE_PROPERTY_PUBLISHED_ON" | translate }}</div>
        <div class="link-list truncated" (click)="$event.stopPropagation()">
          <div class="link-item">
            <a
              href="{{ property.pricePackage.platforms[0].website }}"
              target="_blank"
              >{{ property.pricePackage.platforms[0].name
              }}<span *ngIf="property.pricePackage.platforms.length > 1"
                >,&nbsp;{{ "AND" | translate }}
                {{ property.pricePackage.platforms.length }}
                {{ "MORE" | translate }}</span
              >
            </a>
          </div>
        </div>
        <div class="link-list completed" (click)="$event.stopPropagation()">
          <ng-container
            *ngFor="
              let platform of property.pricePackage.platforms;
              let i = index
            "
          >
            <div class="link-item" *ngIf="i < 4">
              <a href="{{ platform.website }}" target="_blank"
                >{{ platform.name }}<span *ngIf="i !== 3">,&nbsp;</span>
              </a>
            </div>
          </ng-container>
          <div
            class="link-item"
            *ngIf="property.pricePackage.platforms.length > 4"
          >
            &nbsp; {{ "AND" | translate }}
            {{ property.pricePackage.platforms.length - 4 }}
            {{ "MORE" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="section-3"
    *ngIf="property.publishState === PublishState.Published"
  >
    <div ngbDropdown #dropdown="ngbDropdown">
      <button
        class="btn btn-primary basic publish-state published custom-tooltip"
        type="button"
        ngbDropdownToggle
      >
        <span class="tooltiptext right">
          {{ "PUBLISHED_PROPERTY_TOOLTIP" | translate }}
        </span>
        {{ "SINGLE_PROPERTY_PUBLISHED" | translate }}
      </button>
      <ul ngbDropdownMenu>
        <li
          ngbDropdownItem
          (click)="updatePublishState(PublishState.Unpublished)"
        >
          <a> {{ "ADD_PROPERTY_UNPUBLISH" | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div class="section-3" *ngIf="property.publishState === PublishState.Pending">
    <div ngbDropdown #dropdown="ngbDropdown">
      <button
        class="btn btn-primary basic publish-state pending custom-tooltip"
        type="button"
        ngbDropdownToggle
      >
        <span class="tooltiptext right">
          {{ "PENDING_PROPERTY_TOOLTIP" | translate }}
        </span>
        {{ "SINGLE_PROPERTY_PENDING" | translate }}
      </button>
      <ul ngbDropdownMenu>
        <li
          ngbDropdownItem
          (click)="updatePublishState(PublishState.Unpublished)"
        >
          <a> {{ "ADD_PROPERTY_UNPUBLISH" | translate }}</a>
        </li>
        <li
          *ngIf="isAdmin"
          (click)="updatePublishState(PublishState.Published)"
        >
          <a> {{ "PROPERTY_PUBLISH" | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div
    class="section-3"
    *ngIf="property.publishState === PublishState.Unpublished"
  >
    <div ngbDropdown #dropdown="ngbDropdown">
      <button
        class="btn btn-primary basic publish-state unpublished custom-tooltip"
        type="button"
        ngbDropdownToggle
      >
        <span class="tooltiptext right">
          {{ "UNPUBLISHED_PROPERTY_TOOLTIP" | translate }}
        </span>
        {{ "SINGLE_PROPERTY_UNPUBLISHED" | translate }}
      </button>
      <ul ngbDropdownMenu>
        <li
          ngbDropdownItem
          (click)="updatePublishState(PublishState.Published)"
        >
          <a> {{ "PROPERTY_PUBLISH" | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
  <div
    ngbDropdown
    #dropdown="ngbDropdown"
    [placement]="'bottom-end'"
    class="more-options"
  >
    <button class="btn btn-primary basic" type="button" ngbDropdownToggle>
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#vertical'"
        [width]="'24px'"
        [height]="'24px'"
        class="desktop"
      ></svg-icon-sprite>
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#vertical'"
        [width]="'16px'"
        [height]="'16px'"
        class="mobile"
      ></svg-icon-sprite>
    </button>
    <ul ngbDropdownMenu>
      <li ngbDropdownItem (click)="navigateToEdit()">
        <a> {{ "ADD_PROPERTY_EDIT" | translate }}</a>
      </li>
      <li ngbDropdownItem class="delete" (click)="delete()">
        <a> {{ "ADD_PROPERTY_DELETE" | translate }}</a>
      </li>
    </ul>
  </div>
</div>

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PublishState } from '../enumerations/publish-state.enumeration';
import { IProperty } from '../interfaces/property.interface';

@Injectable({
  providedIn: 'root',
})
export class PropertyService {
  constructor(private http: HttpClient) {}

  /**
   * Fetches every property user owns.
   * @returns {Observable<IProperty[]>} The response
   */
  public getAll(): Observable<IProperty[]> {
    return this.http
      .get<IProperty[]>(`${environment.endpoint}/property/getAll`)
      .pipe(
        map((properties: IProperty[]) => {
          return properties;
        }),
      );
  }

  /**
   * Fetches the property with the given id.
   * @param {number} propertyId The id of the property.
   * @returns {IProperty} The response
   */
  public getProperty(propertyId: number): Observable<IProperty> {
    return this.http
      .get<IProperty>(`${environment.endpoint}/property/get/${propertyId}`)
      .pipe(
        map((property: IProperty) => {
          return property;
        }),
      );
  }

  /**
   * Updates the publish state of the given property.
   * @param {number} propertyId The id of the property.
   * @param {PublishState} publishState The new publish state.
   * @returns {Observable<any>} The response
   */
  public updatePropertyPublishState(
    propertyId: number,
    publishState: PublishState,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.http.post(
      `${environment.endpoint}/property/publish/${propertyId}`,
      { publishState },
    );
  }

  /**
   * Deletes the given property.
   * @param {number} propertyId The id of the property.
   * @returns {Observable<any>} The response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public deleteProperty(propertyId: number): Observable<any> {
    return this.http.post(
      `${environment.endpoint}/property/delete/${propertyId}`,
      {},
    );
  }

  /**
   * Edits the photos of a property.
   * @param {number} propertyId The property id.
   * @param {number[]} existingPhotos The existing photos.
   * @param {File[]} photos The new photos.
   * @returns {Observable<any>} The response
   */
  public editPhotos(
    propertyId: number,
    existingPhotos: number[],
    photos: File[],
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const formData = new FormData();

    if (photos != null && photos.length > 0) {
      photos.forEach((photo) => {
        formData.append('photos', photo);
      });
    }

    if (existingPhotos != null && existingPhotos.length > 0) {
      existingPhotos.forEach((photo) => {
        formData.append('existingPhotoIds', photo.toString());
      });
    }

    return this.http.post(
      `${environment.endpoint}/property/edit/photos/${propertyId}`,
      formData,
    );
  }

  /**
   * Creates the given property.
   * @param {IProperty} property The property to be created.
   * @param {File[]} photos The property photos.
   * @param {boolean} publish Indicates if the property should be published.
   * @returns {Observable<any>} The response
   */
  public create(
    property: IProperty,
    photos: File[],
    publish: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const formData = new FormData();

    if (photos != null && photos.length > 0) {
      photos.forEach((photo) => {
        formData.append('photos', photo);
      });
    }

    for (const key of Object.keys(property)) {
      const propertyValue = property[key as keyof typeof property];
      if (propertyValue != null) {
        if (
          typeof propertyValue === 'string' &&
          propertyValue?.toString().trim() === ''
        ) {
          continue;
        }

        if (Array.isArray(propertyValue)) {
          for (const item of propertyValue) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formData.append(key, item as any);
          }

          continue;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formData.append(key, propertyValue as any);
      }
    }

    if (publish) {
      return this.http.post(
        `${environment.endpoint}/property/create/publish`,
        formData,
      );
    } else {
      return this.http.post(
        `${environment.endpoint}/property/create`,
        formData,
      );
    }
  }

  /**
   * Edits the given property.
   * @param {number} propertyId The id of the property.
   * @param {IProperty} property The property to be created.
   * @param {File[]} photos The property photos.
   * @param {boolean} publish Indicates if the property will be published.
   * @returns {Observable<any>} The response
   */
  public edit(
    propertyId: number,
    property: IProperty,
    photos: File[],
    publish: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    const formData = new FormData();

    if (photos != null && photos.length > 0) {
      photos.forEach((photo) => {
        formData.append('photos', photo);
      });
    }

    for (const key of Object.keys(property)) {
      const propertyValue = property[key as keyof typeof property];
      if (propertyValue != null) {
        if (
          typeof propertyValue === 'string' &&
          propertyValue?.toString().trim() === ''
        ) {
          continue;
        }

        if (Array.isArray(propertyValue)) {
          for (const item of propertyValue) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            formData.append(key, item as any);
          }

          continue;
        }

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        formData.append(key, propertyValue as any);
      }
    }

    if (publish) {
      return this.http.post(
        `${environment.endpoint}/property/edit/publish/${propertyId}`,
        formData,
      );
    } else {
      return this.http.post(
        `${environment.endpoint}/property/edit/${propertyId}`,
        formData,
      );
    }
  }
}

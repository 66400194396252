import { Component } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { first } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';
import { SignUpModalComponent } from '../sign-up-modal/sign-up-modal.component';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  // Public/private properties.
  public signUpForm = this.fb.group(
    {
      email: ['', Validators.compose([Validators.email, Validators.required])],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      acceptTerms: [false, Validators.requiredTrue],
    },
    { validators: this.matchPasswords() },
  );
  public error: string = '';
  public frontOfficeURL: string;
  public blurStates = {
    email: false,
    password: false,
    confirmPassword: false,
  };
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private accountService: AccountService,
    private modalService: NgbModal,
    private $gaService: GoogleAnalyticsService,
  ) {
    this.frontOfficeURL = environment.frontOfficeURL;
  }

  /**
   * Checks if the passwords do match.
   * @returns {ValidatorFn} The validation's result
   */
  private matchPasswords(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = control.value.password;
      const confirmPassword = control.value.confirmPassword;

      return password === confirmPassword ? null : { notSame: true };
    };
  }

  /**
   * Navigates user to sign in page.
   */
  public navigateToSignIn(): void {
    this.router.navigate(['/sign-in']);
    window.scroll(0, 0);
  }

  /**
   * Navigates user to sign up options page.
   */
  public navigateToSignUpOptions(): void {
    this.router.navigate(['/sign-up-options']);
    window.scroll(0, 0);
  }

  /**
   * Submits form if it's valid.
   */
  public onSubmit(): void {
    if (this.signUpForm.valid) {
      this.$gaService.event(
        'SignUp-CreateAccountWithEmail-CTA',
        'Click',
        'SignUp-CTA',
      );
      this.accountService
        .signUp(
          this.signUpForm.value.email,
          this.signUpForm.value.password,
          this.signUpForm.value.confirmPassword,
          this.signUpForm.value.acceptTerms,
        )
        .pipe(first())
        .subscribe({
          next: () => {
            this.modalService.open(SignUpModalComponent, {
              centered: false,
              size: 'xl',
              backdrop: true,
            });
            this.router.navigate(['/sign-in']);
          },
          error: (error) => {
            this.error = error.error.message;
          },
        });
    }
  }
}

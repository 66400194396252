import { Component, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  // Public/Private properties.
  @Input() public title: string = '';
  @Input() public text: string = '';

  constructor(private modalService: NgbModal) {}

  /**
   * Navigates user to contact page.
   */
  public dismissModal(): void {
    this.modalService.dismissAll();
  }
}

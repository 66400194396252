export enum ExtraAttribute {
  Roof_Garden,
  Safety_Door,
  Awnings,
  Electrical_Tents,
  Electrical_Devices,
  Furnished,
  A_C,
  Satellite,
  Safety_Alarm,
  Elevator,
  Internal_Elevator,
  CCTV,
  Mosquito_Screen,
  Solar_Panel,
  Entry_Stairs,
  Painted,
  Storage_Room,
  Newly_Built,
  Garden,
  Safety_Box,
  Pool,
  BBQ,
  Playroom,
  Dressing_Room,
  Pets_Free,
  Jacuzzi,
  Cellar,
  Ramp_Handling,
  Freight_Elevator,
  Aeration,
  Industrial_Area,
  Structured_Wiring,
  Fire_Extinguishing_System,
  Contains_Building,
  With_Building_Permit,
  Panoramic,
  Fenced,
  With_Drilling,
  With_Water_And_Electricity,
  Corner,
  With_Building_Zone,
  Coastal,
  Buildable,
  Industrial_Floor,
  Preserved,
  Outside_Of_City_Limits,
  Inside_City_Limits,
  Investement,
  Smart_House,
  Prefabricated,
  Recently_Renovated,
  Seafront,
  Electric_Shutters,
  Country_House,
  Penthouse,
  Health_Regulated_Establishment,
  Funnel,
  Fireplace,
}

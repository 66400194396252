import { Component, Input, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar-logged-in',
  templateUrl: './navigation-bar-logged-in.component.html',
  styleUrls: ['./navigation-bar-logged-in.component.scss'],
})
export class NavigationBarLoggedInComponent implements OnInit {
  // Inputs/Outputs
  @Input() public isLight = false;
  @Input() public addProperty = false;

  // Public properites.
  public scrollingPosition: number;
  public showSlidingMenu: boolean = false;
  public email: string = '';
  public frontOfficeURL: string;
  public dropdownIsOpened = false;

  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {
    this.scrollingPosition = 0;
    this.frontOfficeURL = environment.frontOfficeURL;
  }
  // DOM manipulation.
  /**
   *
   */
  @HostListener('window:scroll', ['$event'])
  public updateScrollPosition(): void {
    this.scrollingPosition = window.scrollY;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.showSlidingMenu = false;
    this.email = this.accountService.accountValue?.email ?? '';
  }

  /**
   * closeMenu
   */
  public closeMenu(): void {
    this.showSlidingMenu = !this.showSlidingMenu;
  }

  /**
   * Logs the user out.
   */
  public logout(): void {
    this.accountService.logout();
  }
}

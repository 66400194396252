<div class="close-button" (click)="dismissModal()">
  <div class="text">{{ "MODAL_CLOSE" | translate }}</div>
  <div class="icon">
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#close'"
      [width]="'24px'"
      [height]="'24px'"
    ></svg-icon-sprite>
  </div>
</div>
<div class="first-time-modal">
  <div class="hero icon">
    <img src="../../../../assets/icons/breello-about.svg" alt="" />
  </div>
  <div class="title">{{ "FIRST_TIME_MODAL_WELCOME" | translate }}</div>
  <div
    class="main-text"
    [innerHTML]="'FIRST_TIME_MODAL_TEXT' | translate"
  ></div>
  <div class="button" (click)="navigateToCreate()">
    <div class="text">{{ "LANDING_PAGE_LIST_PROPERTY" | translate }}</div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#arrow-right'"
        [width]="'24px'"
        [height]="'24px'"
      ></svg-icon-sprite>
    </div>
  </div>
</div>

<div class="sliding-menu">
  <div class="top">
    <div class="close-button" (click)="closeMenu()">
      <div class="icon">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#close'"
          [width]="'32px'"
          [height]="'32px'"
        ></svg-icon-sprite>
      </div>
    </div>
    <div class="links" *ngIf="!loggedIn">
      <div class="link first" (click)="navigateToSignIn()">
        <div class="text">{{ "NAVIGATION_BAR_HOME" | translate }}</div>
      </div>
      <div class="link">
        <a href="{{ frontOfficeURL }}/about">{{
          "NAVIGATION_BAR_HOW_IT_WORKS" | translate
        }}</a>
      </div>
      <div class="link">
        <a href="{{ frontOfficeURL }}/about">{{
          "NAVIGATION_BAR_CONTACT" | translate
        }}</a>
      </div>
      <div class="link last">
        <a href="{{ frontOfficeURL }}/about#faq">{{
          "NAVIGATION_BAR_FAQ" | translate
        }}</a>
      </div>
    </div>
    <div class="links" *ngIf="loggedIn">
      <div class="link first" (click)="navigateToMyProperties()">
        <div class="text">{{ "NAVIGATION_BAR_MY_PROPERTIES" | translate }}</div>
      </div>
      <div class="link" (click)="navigateToAddProperty()">
        <div class="text">{{ "NAVIGATION_BAR_ADD_PROPERTY" | translate }}</div>
      </div>
      <div class="link last" (click)="logout()">
        <div class="text">{{ "NAVIGATION_BAR_LOGOUT" | translate }}</div>
      </div>
    </div>
    <!-- <div class="button-outline">
      <div class="text">
        <div class="lang">English</div>
        <div class="icon">
          <svg-icon-sprite
            [src]="'assets/sprites/sprite.svg#down'"
            [width]="'10px'"
            [height]="'20px'"
          ></svg-icon-sprite>
        </div>
      </div>
    </div> -->
    <div class="bottom" *ngIf="!loggedIn">
      <div class="button-fill" (click)="navigateToSignIn()">
        <div class="text">{{ "NAVIGATION_BAR_SIGN_IN" | translate }}</div>
      </div>
    </div>
  </div>
  <!-- <div class="social-media">
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#twitter-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#facebook-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#linkedin-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#instagram-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
    <div class="icon">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#youtube-copy'"
        [width]="'32px'"
        [height]="'32px'"
      ></svg-icon-sprite>
    </div>
  </div> -->
</div>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'url' })
export class UrlPipe implements PipeTransform {
  /**
   * Fixes url string
   * @param {string} url The url
   * @returns {string} The fixed url
   */
  transform(url: string): string {
    if (url == null || url.trim() === '') {
      return url;
    }

    if (url.substring(0, 8) === 'https://') {
      return url.substring(8);
    } else if (url.substring(0, 7) === 'http://') {
      return url.substring(7);
    }

    return url;
  }
}

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { first, take, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';
import isEqual from 'lodash-es/isEqual';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],
})
export class SignInComponent implements OnInit, OnDestroy {
  // Public/private properties.
  public loginForm = this.fb.group({
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
      ]),
    ],
    password: ['', Validators.required],
    remember: [false, Validators.required],
  });

  public error?: string;
  public blurStates = {
    email: false,
    password: false,
  };
  public frontOfficeURL: string;
  private currentSocialUser?: SocialUser;
  private destroy$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private accountService: AccountService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private authService: SocialAuthService,
  ) {
    this.frontOfficeURL = environment.frontOfficeURL;
  }

  /**
   *
   */
  ngOnInit(): void {
    window.scroll(0, 0);

    this.route.queryParams.subscribe((params) => {
      if (
        params != null &&
        params['verify'] != null &&
        params['verify'] === 'true'
      ) {
        const modalRef = this.modalService.open(GenericModalComponent, {
          centered: false,
          size: 'xl',
          backdrop: true,
        });
        modalRef.componentInstance.title = 'VERIFY_EMAIL_MODAL_TITLE';
        modalRef.componentInstance.text = 'VERIFY_EMAIL_MODAL_TEXT';
      }
    });

    // Listens for social logins.
    this.socialLoginListener();
  }

  private socialLoginListener(): void {
    this.authService.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user == null) {
        return;
      }

      if (
        this.currentSocialUser != null &&
        isEqual(this.currentSocialUser, user)
      ) {
        return;
      }

      this.currentSocialUser = user;

      if (user.provider === GoogleLoginProvider.PROVIDER_ID) {
        this.accountService
          .signInGoogle(user.idToken)
          .pipe(first())
          .subscribe({
            next: () => {
              this.router.navigate(['/my-properties']);
            },
            error: () => { },
          });
      } else if (user.provider === FacebookLoginProvider.PROVIDER_ID) {
        this.accountService
          .signInFacebook(user.authToken)
          .pipe(first())
          .subscribe({
            next: () => {
              this.router.navigate(['/my-properties']);
            },
            error: () => { },
          });
      }
    });
  }

  /**
   * Submits form if it's valid.
   */
  public onSubmit(): void {
    if (this.loginForm.valid) {
      this.accountService
        .signIn(this.loginForm.value.email, this.loginForm.value.password)
        .pipe(first())
        .subscribe({
          next: () => {
            this.router.navigate(['/my-properties']);
          },
          error: (error) => {
            this.error = error.error.message;
            this.loginForm.controls.email.setErrors({ loginError: true });
            this.loginForm.controls.password.setErrors({ loginError: true });

            // On the first change
            this.loginForm.valueChanges.pipe(take(1)).subscribe(() => {
              // Re-evaluating the controls.
              if (this.blurStates.email == false) {
                this.loginForm.controls.email.updateValueAndValidity();
              }
              if (this.blurStates.password == false) {
                this.loginForm.controls.password.updateValueAndValidity();
              }

              // Removing the error message.
              this.error = undefined;
            });
          },
        });
    }
  }

  /**
   * Navigates user to sign up options page.
   */
  public navigateToSignUpOptions(): void {
    this.router.navigate(['/sign-up-options']);
    window.scroll(0, 0);
  }

  /**
   * Navigates user to forgot password page.
   */
  public navigateToForgotPassword(): void {
    this.router.navigate(['/forgot-password']);
    window.scroll(0, 0);
  }

  /**
   * Signs in with Facebook
   */
  public signInWithFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

export enum HeatingType {
  Gas,
  Diesel,
  Geothermal,
  Radiator,
  Air_Condition,
  Heat_Pump,
  Underfloor_Heating,
  Preinstalled,
  Pellet,
}

export enum EnergyClass {
  A_Plus,
  A,
  B_Plus,
  B,
  C,
  D,
  E,
  F,
  G,
}

export enum SubType {
  Apartment,
  Maisonette,
  Single_Family_Home,
  Studio,
  Loft,
  Other_Residential_Property,
  Office,
  Store,
  Warehouse,
  Industrial_Area,
  Craft_Space,
  Other_Commercial_Property,
  Plot,
  Patch,
  Island,
  Other_Land,
  Hotel,
  Air_Rights,
  Parking,
  Building,
  Residence_Complex,
  Business,
  Other,
}

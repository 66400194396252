<div
  class="navigation-bar"
  [ngClass]="{ scrolled: scrollingPosition !== 0, light: isLight }"
>
  <div class="logo">
    <a href="{{ frontOfficeURL }}">
      <img src="assets/icons/logo-light.svg" alt="" class="logo-light" />
      <img src="assets/icons/logo-dark.svg" alt="" class="logo-dark" />
    </a>
  </div>
  <div class="navigation-buttons">
    <div class="button first">
      <div class="text">
        <a href="{{ frontOfficeURL }}/about">
          {{ "NAVIGATION_BAR_HOW_IT_WORKS" | translate }}
        </a>
      </div>
    </div>
    <div class="button second">
      <div class="text">
        <a href="{{ frontOfficeURL }}/contact">
          {{ "NAVIGATION_BAR_CONTACT" | translate }}
        </a>
      </div>
    </div>
    <!-- <div class="button">
      <div class="text">
        En
        <div class="icon">
          <svg-icon-sprite
            [src]="'assets/sprites/sprite.svg#down'"
            [width]="'10px'"
            [height]="'20px'"
          ></svg-icon-sprite>
        </div>
      </div>
    </div> -->
    <!-- <div class="button-border">
      <div class="text">{{ "NAVIGATION_BAR_SIGN_IN" | translate }}</div>
    </div> -->
    <div class="button-full">
      <div class="text">
        <a href="{{ frontOfficeURL }}/register">
          {{ "NAVIGATION_BAR_LIST" | translate }}
        </a>
      </div>
    </div>
    <div class="button-border">
      <a href="#">
        <div class="text">
          {{ "NAVIGATION_BAR_SIGN_IN" | translate }}
        </div>
      </a>
    </div>
  </div>
</div>
<div
  class="navigation-bar-mobile"
  [ngClass]="{
    light: isLight,
    slidingMenu: showSlidingMenu,
    scrolled: scrollingPosition !== 0,
  }"
>
  <div class="visible-menu">
    <div class="logo" *ngIf="!showSlidingMenu">
      <a href="{{ frontOfficeURL }}">
        <img src="assets/icons/logo-light.svg" alt="" class="logo-light" />
        <img src="assets/icons/logo-dark.svg" alt="" class="logo-dark" />
      </a>
    </div>
    <div class="icon" (click)="openMenu()" *ngIf="!showSlidingMenu">
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#navigational-menu'"
        [width]="'24px'"
        [height]="'24px'"
      ></svg-icon-sprite>
    </div>
  </div>
  <div
    class="sliding-menu"
    [ngClass]="{ open: showSlidingMenu, close: !showSlidingMenu }"
  >
    <app-sliding-menu (menuClosed)="closeMenu()"></app-sliding-menu>
  </div>
</div>

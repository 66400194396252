export enum Floor {
  Basement,
  Semi_Basement,
  Ground_Floor,
  Semi_Floor,
  Floor_1,
  Floor_2,
  Floor_3,
  Floor_4,
  Floor_5,
  Floor_6,
  Floor_7,
  Floor_8,
  Floor_9,
  Floor_10,
  Floor_11,
  Floor_12,
  Attic,
}

<app-navigation-bar-logged-in
  [isLight]="true"
  [addProperty]="false"
></app-navigation-bar-logged-in>

<div class="settings">
  <div class="title">
    {{ "SETTINGS_TITLE" | translate }}
  </div>

  <div class="field email">
    <div class="label">{{ "INPUT_EMAIL" | translate }}</div>
    <div class="input">
      <input type="email" value="{{ email }}" disabled />
    </div>
  </div>

  <div class="field password" *ngIf="changePasswordStatus === false">
    <div class="label">{{ "SETTINGS_PASSWORD" | translate }}</div>
    <div class="input">
      <input type="password" value="nothing" disabled />
    </div>
    <div class="success" *ngIf="passwordChanged">
      {{ "SETTINGS_CHANGE_PASSWORD_SUCCESS" | translate }}
    </div>
  </div>

  <form [formGroup]="updatePasswordForm" *ngIf="changePasswordStatus">
    <div class="field password">
      <div class="label">{{ "INPUT_PASSWORD_CURRENT" | translate }}</div>
      <div class="input">
        <input
          type="password"
          formControlName="currentPassword"
          placeholder=" {{ 'INPUT_PASSWORD_PLACEHOLDER' | translate }}"
          [ngClass]="{
            'error-input':
              updatePasswordForm.get('currentPassword')?.invalid &&
              (updatePasswordForm.get('currentPassword')?.dirty ||
                updatePasswordForm.get('currentPassword')?.touched),
          }"
        />
      </div>
      <div
        class="error"
        *ngIf="
          updatePasswordForm.get('currentPassword')?.invalid &&
          (updatePasswordForm.get('currentPassword')?.dirty ||
            updatePasswordForm.get('currentPassword')?.touched)
        "
      >
        <ng-container
          *ngIf="updatePasswordForm.get('currentPassword')?.errors?.required"
        >
          {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
        </ng-container>
      </div>
    </div>

    <div class="field password">
      <div class="label">{{ "INPUT_PASSWORD_NEW" | translate }}</div>
      <div class="input">
        <input
          type="password"
          formControlName="password"
          placeholder=" {{ 'INPUT_PASSWORD_NEW_PLACEHOLDER' | translate }}"
          [ngClass]="{
            'error-input':
              updatePasswordForm.get('password')?.invalid &&
              (updatePasswordForm.get('password')?.dirty ||
                updatePasswordForm.get('password')?.touched),
          }"
        />
      </div>
      <div
        class="error"
        *ngIf="
          updatePasswordForm.get('password')?.invalid &&
          (updatePasswordForm.get('password')?.dirty ||
            updatePasswordForm.get('password')?.touched)
        "
      >
        <ng-container
          *ngIf="updatePasswordForm.get('password')?.errors?.required"
        >
          {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
        </ng-container>
      </div>
    </div>

    <div class="field password">
      <div class="label">{{ "INPUT_PASSWORD_RETYPE" | translate }}</div>
      <div class="input">
        <input
          type="password"
          formControlName="confirmPassword"
          placeholder=" {{
            'INPUT_PASSWORD_NEW_RETYPE_PLACEHOLDER' | translate
          }}"
          [ngClass]="{
            'error-input':
              updatePasswordForm.get('confirmPassword')?.invalid &&
              (updatePasswordForm.get('confirmPassword')?.dirty ||
                updatePasswordForm.get('confirmPassword')?.touched),
          }"
        />
      </div>
      <div
        class="error"
        *ngIf="
          (updatePasswordForm.get('confirmPassword')?.invalid ||
            updatePasswordForm.errors?.notSame) &&
          (updatePasswordForm.get('confirmPassword')?.dirty ||
            updatePasswordForm.get('confirmPassword')?.touched)
        "
      >
        <ng-container
          *ngIf="updatePasswordForm.get('confirmPassword')?.errors?.required"
        >
          {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
        </ng-container>
        <ng-container
          *ngIf="
            updatePasswordForm.errors?.notSame &&
            !updatePasswordForm.get('confirmPassword')?.errors?.required
          "
        >
          {{ "INPUT_PASSWORD_ERROR_MATCH" | translate }}
        </ng-container>
      </div>
      <div class="error">
        {{ error ?? "" | translate }}
      </div>
    </div>

    <button
      type="submit"
      class="button-primary"
      (click)="changePassword()"
      [disabled]="!updatePasswordForm.valid"
    >
      <div class="text">
        {{ "SETTINGS_CHANGE_PASSWORD" | translate }}
      </div>
    </button>
  </form>

  <div
    class="change-password"
    *ngIf="!!!changePasswordStatus"
    (click)="changePasswordStatus = true"
  >
    {{ "SETTINGS_CHANGE_PASSWORD" | translate }}
  </div>

  <form [formGroup]="removeAccountForm" *ngIf="removeAccountStatus">
    <div class="remove-account-title">
      {{ "SETTINGS_REMOVE_ACCOUNT_TITLE" | translate }}
    </div>

    <div class="field password">
      <div class="label">{{ "INPUT_PASSWORD_CURRENT" | translate }}</div>
      <div class="input">
        <input
          type="password"
          formControlName="password"
          placeholder=" {{ 'INPUT_PASSWORD_PLACEHOLDER' | translate }}"
          [ngClass]="{
            'error-input':
              removeAccountForm.get('password')?.invalid &&
              (removeAccountForm.get('password')?.dirty ||
                removeAccountForm.get('password')?.touched),
          }"
        />
      </div>
      <div
        class="error"
        *ngIf="
          removeAccountForm.get('password')?.invalid &&
          (removeAccountForm.get('password')?.dirty ||
            removeAccountForm.get('password')?.touched)
        "
      >
        <ng-container
          *ngIf="removeAccountForm.get('password')?.errors?.required"
        >
          {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
        </ng-container>
      </div>
      <div class="error">
        {{ error ?? "" | translate }}
      </div>
    </div>

    <div class="buttons">
      <button
        type="submit"
        class="button-primary"
        (click)="removeAccount()"
        [disabled]="!removeAccountForm.valid"
      >
        <div class="text">
          {{ "SETTINGS_REMOVE_ACCOUNT" | translate }}
        </div>
      </button>

      <button class="button-cancel" (click)="cancel()">
        <div class="text">
          {{ "SETTINGS_REMOVE_ACCOUNT_CANCEL" | translate }}
        </div>
      </button>
    </div>
  </form>

  <div
    class="remove-account"
    *ngIf="!!!removeAccountStatus"
    (click)="removeAccountStatus = true"
  >
    {{ "SETTINGS_REMOVE_ACCOUNT" | translate }}
  </div>
</div>

<app-footer></app-footer>

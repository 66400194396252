<app-navigation-bar [isLight]="true"></app-navigation-bar>
<div class="sign-in-body">
  <div class="main-content">
    <div class="two-col-content">
      <div class="second-col">
        <div class="back-link" (click)="navigateToSignUpOptions()">
          <div class="back-link-icon">
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#arrow-left'"
              [width]="'16px'"
              [height]="'16px'"
            ></svg-icon-sprite>
          </div>
          <div class="back-link-text">
            {{ "SIGN_UP_BACK" | translate }}
          </div>
        </div>
        <div class="title">
          <div class="text">{{ "SIGN_UP_TITLE" | translate }}</div>
        </div>
        <div class="main-text">
          {{ "SIGN_UP_TEXT" | translate }}
        </div>
        <div class="form">
          <form [formGroup]="signUpForm" (keydown.enter)="onSubmit()">
            <div class="form-section">
              <label>
                <div class="form-section">
                  <div class="text">
                    {{ "INPUT_EMAIL" | translate }}
                  </div>
                  <div class="input">
                    <input
                      type="text"
                      formControlName="email"
                      placeholder=" {{ 'INPUT_EMAIL_PLACEHOLDER' | translate }}"
                      [ngClass]="{
                        'error-input':
                          blurStates.email === false &&
                          signUpForm.get('email')?.invalid &&
                          (signUpForm.get('email')?.dirty ||
                            signUpForm.get('email')?.touched),
                      }"
                      (focus)="blurStates.email = true"
                      (blur)="blurStates.email = false"
                    />
                    <div
                      class="error-icon"
                      *ngIf="
                        blurStates.email === false &&
                        signUpForm.get('email')?.invalid &&
                        (signUpForm.get('email')?.dirty ||
                          signUpForm.get('email')?.touched)
                      "
                    >
                      <svg-icon-sprite
                        [src]="'assets/sprites/sprite.svg#alert'"
                        [width]="'16px'"
                        [height]="'16px'"
                      ></svg-icon-sprite>
                    </div>
                  </div>
                  <div
                    class="error"
                    *ngIf="
                      blurStates.email === false &&
                      signUpForm.get('email')?.invalid &&
                      (signUpForm.get('email')?.dirty ||
                        signUpForm.get('email')?.touched)
                    "
                  >
                    <ng-container
                      *ngIf="signUpForm.get('email')?.errors?.required"
                    >
                      {{ "INPUT_EMAIL_ERROR_REQUIRED" | translate }}
                    </ng-container>
                    <ng-container
                      *ngIf="signUpForm.get('email')?.errors?.email"
                    >
                      {{ "INPUT_EMAIL_ERROR_INVALID" | translate }}
                    </ng-container>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-section">
              <label>
                <div class="text password">
                  <div class="text-pass">
                    {{ "INPUT_PASSWORD" | translate }}
                  </div>
                </div>
                <div class="input">
                  <input
                    type="password"
                    formControlName="password"
                    placeholder=" {{
                      'INPUT_PASSWORD_PLACEHOLDER' | translate
                    }}"
                    [ngClass]="{
                      'error-input':
                        blurStates.password === false &&
                        signUpForm.get('password')?.invalid &&
                        (signUpForm.get('password')?.dirty ||
                          signUpForm.get('password')?.touched),
                    }"
                    (focus)="blurStates.password = true"
                    (blur)="blurStates.password = false"
                  />
                </div>
                <div
                  class="error"
                  *ngIf="
                    blurStates.password === false &&
                    signUpForm.get('password')?.invalid &&
                    (signUpForm.get('password')?.dirty ||
                      signUpForm.get('password')?.touched)
                  "
                >
                  <ng-container
                    *ngIf="signUpForm.get('password')?.errors?.required"
                  >
                    {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
                  </ng-container>
                </div>
              </label>
            </div>
            <div class="form-section">
              <label>
                <div class="text password">
                  <div class="text-pass">
                    {{ "INPUT_PASSWORD_RETYPE" | translate }}
                  </div>
                </div>
                <div class="input">
                  <input
                    type="password"
                    formControlName="confirmPassword"
                    placeholder=" {{
                      'INPUT_PASSWORD_RETYPE_PLACEHOLDER' | translate
                    }}"
                    [ngClass]="{
                      'error-input':
                        blurStates.confirmPassword === false &&
                        signUpForm.get('confirmPassword')?.invalid &&
                        (signUpForm.get('confirmPassword')?.dirty ||
                          signUpForm.get('confirmPassword')?.touched),
                    }"
                    (focus)="blurStates.confirmPassword = true"
                    (blur)="blurStates.confirmPassword = false"
                  />
                </div>
                <div
                  class="error"
                  *ngIf="
                    blurStates.confirmPassword === false &&
                    (signUpForm.get('confirmPassword')?.invalid ||
                      signUpForm.errors?.notSame) &&
                    (signUpForm.get('confirmPassword')?.dirty ||
                      signUpForm.get('confirmPassword')?.touched)
                  "
                >
                  <ng-container
                    *ngIf="signUpForm.get('confirmPassword')?.errors?.required"
                  >
                    {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
                  </ng-container>
                  <ng-container
                    *ngIf="
                      signUpForm.errors?.notSame &&
                      !signUpForm.get('confirmPassword')?.errors?.required
                    "
                  >
                    {{ "INPUT_PASSWORD_ERROR_MATCH" | translate }}
                  </ng-container>
                </div>
                <div class="error">
                  {{ error | translate }}
                </div>
              </label>
            </div>
            <div class="form-section-checkbox">
              <div class="input">
                <input
                  type="checkbox"
                  formControlName="acceptTerms"
                  id="acceptTerms"
                  class="form-check-input"
                />
              </div>
              <div class="text">
                <label for="acceptTerms" class="form-check-label">
                  {{ "SIGN_UP_AGREE_TERMS" | translate }}
                  <a href="{{ frontOfficeURL }}/terms">
                    {{ "SIGN_UP_AGREE_TERMS_LINK" | translate }}
                  </a>
                </label>
              </div>
            </div>
          </form>
          <button
            type="submit"
            class="button"
            (click)="onSubmit()"
            [disabled]="!signUpForm.valid"
          >
            <div class="text">{{ "SIGN_UP_BUTTON" | translate }}</div>
          </button>
        </div>
        <div class="sign-up">
          <div class="text">{{ "SIGN_UP_ALREADY_HAVE_ONE" | translate }}</div>
          <div class="link" (click)="navigateToSignIn()">
            {{ "SIGN_UP_SIGN_IN" | translate }}
          </div>
        </div>
        <div class="help">
          <a href="{{ frontOfficeURL }}/contact">
            {{ "SIGN_IN_NEED_HELP" | translate }}
          </a>
        </div>
      </div>
      <div class="first-col">
        <div class="image">
          <img src="../../../assets/icons/breello-sign-up.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { Action } from 'src/app/enumerations/action.enumeration';
import { Floor } from 'src/app/enumerations/floor.enumeration';
import { PublishState } from 'src/app/enumerations/publish-state.enumeration';
import { Role } from 'src/app/enumerations/role.enumeration';
import { SubType } from 'src/app/enumerations/sub-type.enumeration';
import { Type } from 'src/app/enumerations/type.enumeration';
import { IProperty } from 'src/app/interfaces/property.interface';
import { AccountService } from 'src/app/services/account.service';
import { PropertyService } from 'src/app/services/property.service';
import { environment } from 'src/environments/environment';
import { ActionModalComponent } from '../action-modal/action-modal.component';

@Component({
  selector: 'app-single-property',
  templateUrl: './single-property.component.html',
  styleUrls: ['./single-property.component.scss'],
})
export class SinglePropertyComponent {
  // Inputs/ outputs.
  @Input() public property?: IProperty;

  // Public properties.
  public PublishState = PublishState;
  public Action = Action;
  public Floor = Floor;
  public Type = Type;
  public SubType = SubType;
  public api: string;
  public isAdmin: boolean;

  constructor(
    private router: Router,
    private propertyService: PropertyService,
    private modalService: NgbModal,
    private accountService: AccountService,
  ) {
    this.api = environment.endpoint;

    this.isAdmin = this.accountService.accountValue?.role === Role.Admin;
  }

  /**
   * Checks if the property can be published.
   * @returns {boolean} True if the property can be published, false if it can't
   */
  private canPropertyBePublished(): boolean {
    if (this.property == null) {
      return false;
    }

    if (this.property.action == null) {
      return false;
    }

    if (this.property.type == null) {
      return false;
    }

    if (this.property.price == null) {
      return false;
    }

    if (this.property.rooms == null) {
      return false;
    }

    if (this.property.size == null) {
      return false;
    }

    if (this.property.address == null) {
      return false;
    }

    if (this.property.constructionYear == null) {
      return false;
    }

    if (
      this.property.contactEmails == null ||
      this.property.contactEmails.length == 0
    ) {
      return false;
    }

    if (
      this.property.contactPhones == null ||
      this.property.contactPhones.length == 0
    ) {
      return false;
    }

    return true;
  }

  /**
   * Navigates to the edit page of the property.
   */
  public navigateToEdit(): void {
    this.router.navigate(['/edit-property', this.property?.id]);
  }

  /**
   * Updates property's publish state.
   * @param {PublishState} publishState The new publish state.
   */
  public updatePublishState(publishState: PublishState): void {
    const modalRef = this.modalService.open(ActionModalComponent, {
      centered: false,
      size: 'xl',
      backdrop: true,
      windowClass: 'action-dialog-modal',
    });

    if (publishState === PublishState.Unpublished) {
      modalRef.componentInstance.title = 'MODAL_UNPUBLISH_PROPERTY_TITLE';
      modalRef.componentInstance.text = 'MODAL_UNPUBLISH_PROPERTY_TEXT';
      modalRef.componentInstance.icon = 'warning_fill';
      modalRef.componentInstance.type = 'warning';
      modalRef.componentInstance.primaryButtonText =
        'MODAL_UNPUBLISH_PROPERTY_ACTION';
      modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';
    } else {
      if (this.canPropertyBePublished()) {
        modalRef.componentInstance.title = 'MODAL_PUBLISH_PROPERTY_TITLE';
        modalRef.componentInstance.text = 'MODAL_PUBLISH_PROPERTY_TEXT';
        modalRef.componentInstance.icon = 'warning_fill';
        modalRef.componentInstance.type = 'default';
        modalRef.componentInstance.primaryButtonText =
          'MODAL_PUBLISH_PROPERTY_ACTION';
        modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';
      } else {
        modalRef.componentInstance.title = 'Αδυναμία δημοσίευσης';
        modalRef.componentInstance.text =
          // eslint-disable-next-line max-len
          'Το ακίνητο δεν μπορεί να δημοσιευτεί καθώς δεν έχουν συμπληρωθεί τα απαραίτητα πεδία. Μπορείς να τα συμπληρώσεις κατά την επεξεργασία του ακινήτου.';
        modalRef.componentInstance.icon = 'warning_fill';
        modalRef.componentInstance.type = 'warning';
        modalRef.componentInstance.primaryButtonText = 'ΕΠΕΞΕΡΓΑΣΙΑ';
        modalRef.componentInstance.secondaryButtonText = 'ΑΚΥΡΩΣΗ';

        modalRef.result.then(
          () => {
            this.router.navigate(['/edit-property', this.property?.id]);
          },
          () => {},
        );
      }
    }

    modalRef.result.then(
      () => {
        if (this.property) {
          this.propertyService
            .updatePropertyPublishState(this.property.id, publishState)
            .pipe(first())
            .subscribe({
              next: () => {
                location.reload();
              },
              error: () => {},
            });
        }
      },
      () => {},
    );
  }

  /**
   * Deletes the property.
   */
  public delete(): void {
    const modalRef = this.modalService.open(ActionModalComponent, {
      centered: false,
      size: 'xl',
      backdrop: true,
      windowClass: 'action-dialog-modal',
    });

    modalRef.componentInstance.title = 'MODAL_REMOVE_PROPERTY_TITLE';
    modalRef.componentInstance.text = 'MODAL_REMOVE_PROPERTY_TEXT';
    modalRef.componentInstance.icon = 'alert';
    modalRef.componentInstance.type = 'high-risk';
    modalRef.componentInstance.primaryButtonText =
      'MODAL_REMOVE_PROPERTY_ACTION';
    modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';

    modalRef.result.then(
      () => {
        if (this.property) {
          this.propertyService
            .deleteProperty(this.property.id)
            .pipe(first())
            .subscribe({
              next: () => {
                location.reload();
              },
              error: () => {},
            });
        }
      },
      () => {},
    );
  }
}

import { SocialAuthService } from '@abacritt/angularx-social-login';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { Account } from 'src/app/models/account';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AccountService {
  public accountSubject: BehaviorSubject<Account | null>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private refreshTokenTimeout: any;

  constructor(
    private http: HttpClient,
    private router: Router,
    private authService: SocialAuthService
  ) {
    this.accountSubject = new BehaviorSubject<Account | null>(null);
  }

  /**
   * Get accountValue
   * @returns {Account | null} The account
   */
  public get accountValue(): Account | null {
    return this.accountSubject.value;
  }

  /**
   * Signs up the user.
   * @param {string} email The user email.
   * @param {string} password The password.
   * @param {string} confirmPassword The password confirmation.
   * @param {boolean} acceptTerms The accept terms indicators.
   * @returns {Observable<any>} The response
   */
  public signUp(
    email: string,
    password: string,
    confirmPassword: string,
    acceptTerms: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.http
      .post(`${environment.endpoint}/accounts/signup`, {
        email,
        password,
        confirmPassword,
        acceptTerms,
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((data: any) => {
          return data;
        }),
      );
  }

  /**
   * Signs in the user.
   * @param {string} email The user email.
   * @param {string} password The password.
   * @returns {Observable<any>} The response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public signIn(email: string, password: string): Observable<any> {
    return this.http
      .post(
        `${environment.endpoint}/accounts/signin`,
        {
          email,
          password,
        },
        { withCredentials: true },
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((account: any) => {
          this.accountSubject.next(account);
          this.startRefreshTokenTimer();
          return account;
        }),
      );
  }

  /**
   * Sends the forgot password request.
   * @param {string} email The user email.
   * @returns {Observable<any>} The response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public forgotPassword(email: string): Observable<any> {
    return this.http
      .post(`${environment.endpoint}/accounts/forgot`, {
        email,
      })
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((data: any) => {
          return data;
        }),
      );
  }

  /**
   * Sends the reset password request.
   * @param {string} token The reset token.
   * @param {string} password The new password.
   * @param {string} confirmPassword The confirm new password.
   * @returns {Observable<any>} The response
   */
  public resetPassword(
    token: string,
    password: string,
    confirmPassword: string,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<any> {
    return this.http
      .post(
        `${environment.endpoint}/accounts/reset`,
        {
          token,
          password,
          confirmPassword,
        },
        { withCredentials: true },
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((data: any) => {
          return data;
        }),
      );
  }

  /**
   * Signs in the user with Google.
   * @param {string} token The google id token.
   * @returns {Observable<any>} The response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public signInGoogle(token: string): Observable<any> {
    return this.http
      .post(
        `${environment.endpoint}/accounts/google-signin`,
        { token },
        { withCredentials: true },
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((account: any) => {
          this.accountSubject.next(account);
          this.startRefreshTokenTimer();
          return account;
        }),
      );
  }

  /**
   * Signs in the user with Facebook.
   * @param {string} token the token
   * @returns {Observable<any>} The response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public signInFacebook(token: string): Observable<any> {
    return this.http
      .post(
        `${environment.endpoint}/accounts/facebook-signin`,
        { token },
        { withCredentials: true },
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((account: any) => {
          this.accountSubject.next(account);
          this.startRefreshTokenTimer();
          return account;
        }),
      );
  }

  /**
   * Removes user's account.
   * @param {string} password The password.
   * @returns {Observable<any>} The response
   */
  public removeAccount(password: string): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.post<any>(
      `${environment.endpoint}/accounts/remove`,
      {
        password,
      },
      { withCredentials: true },
    );
  }

  /**
   * Changes user's password.
   * @param {string} currentPassword The current password.
   * @param {string} password The new password.
   * @param {string} confirmPassword The confirmation of the new password.
   * @returns {Observable<any>} The response
   */
  public changePassword(
    currentPassword: string,
    password: string,
    confirmPassword: string,
  ): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return this.http.post<any>(
      `${environment.endpoint}/accounts/change-password`,
      {
        currentPassword,
        password,
        confirmPassword,
      },
      { withCredentials: true },
    );
  }

  /**
   * Logs the user out.
   */
  public logout(): void {
    this.http
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .post<any>(
        `${environment.endpoint}/accounts/revoke`,
        {},
        { withCredentials: true },
      )
      .subscribe();
    this.stopRefreshTokenTimer();
    this.accountSubject.next(null);
    this.authService.signOut();
    this.router.navigate(['/sign-in']);
  }

  /**
   * Refreshes the jwt token.
   * @returns {Observable<any>} The response
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public refreshToken(): Observable<any> {
    return this.http
      .post(
        `${environment.endpoint}/accounts/refresh`,
        {},
        { withCredentials: true },
      )
      .pipe(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        map((account: any) => {
          this.accountSubject.next(account);
          this.startRefreshTokenTimer();
          return account;
        }),
      );
  }

  /**
   * Starts the `refresh token` timer.
   */
  public startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(
      atob(this.accountValue?.jwtToken?.split('.')[1] ?? ''),
    );

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(
      () => this.refreshToken().subscribe(),
      timeout,
    );
  }

  /**
   * Stosp the `refresh token` timer.
   */
  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }
}

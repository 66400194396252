<app-navigation-bar [isLight]="true"></app-navigation-bar>
<div class="sign-in-body">
  <div class="main-content">
    <div class="two-col-content">
      <div class="second-col">
        <div class="title">
          <div class="text">{{ "SIGN_IN_TITLE" | translate }}</div>
        </div>
        <div class="main-text">
          {{ "SIGN_IN_TEXT" | translate }}
        </div>
        <div class="form">
          <form [formGroup]="loginForm" (keydown.enter)="onSubmit()">
            <div class="form-section">
              <label>
                <div class="form-section">
                  <div class="text">
                    {{ "INPUT_EMAIL" | translate }}
                  </div>
                  <div class="input">
                    <input
                      type="email"
                      formControlName="email"
                      placeholder=" {{ 'INPUT_EMAIL_PLACEHOLDER' | translate }}"
                      [ngClass]="{
                        'error-input':
                          blurStates.email === false &&
                          loginForm.get('email')?.invalid &&
                          (loginForm.get('email')?.dirty ||
                            loginForm.get('email')?.touched),
                      }"
                      (focus)="blurStates.email = true"
                      (blur)="blurStates.email = false"
                    />
                    <div
                      class="error-icon"
                      *ngIf="
                        blurStates.email === false &&
                        loginForm.get('email')?.invalid &&
                        (loginForm.get('email')?.dirty ||
                          loginForm.get('email')?.touched)
                      "
                    >
                      <svg-icon-sprite
                        [src]="'assets/sprites/sprite.svg#alert'"
                        [width]="'16px'"
                        [height]="'16px'"
                      ></svg-icon-sprite>
                    </div>
                  </div>
                  <div
                    class="error"
                    *ngIf="
                      blurStates.email === false &&
                      loginForm.get('email')?.invalid &&
                      (loginForm.get('email')?.dirty ||
                        loginForm.get('email')?.touched)
                    "
                  >
                    <ng-container
                      *ngIf="loginForm.get('email')?.errors?.required"
                    >
                      {{ "INPUT_EMAIL_ERROR_REQUIRED" | translate }}
                    </ng-container>
                    <ng-container
                      *ngIf="loginForm.get('email')?.errors?.pattern"
                    >
                      {{ "INPUT_EMAIL_ERROR_INVALID" | translate }}
                    </ng-container>
                  </div>
                </div>
              </label>
            </div>
            <div class="form-section">
              <label>
                <div class="text password">
                  <div class="text-pass">
                    {{ "INPUT_PASSWORD" | translate }}
                  </div>
                  <div
                    class="forgot-button"
                    (click)="navigateToForgotPassword()"
                  >
                    {{ "SIGN_IN_FORGOT_PASSWORD" | translate }}
                  </div>
                </div>
                <div class="input">
                  <input
                    type="password"
                    formControlName="password"
                    placeholder=" {{
                      'INPUT_PASSWORD_PLACEHOLDER' | translate
                    }}"
                  />
                </div>
                <div class="error">
                  {{ error ?? "" | translate }}
                </div>
              </label>
            </div>
            <div class="form-section-checkbox">
              <div class="input">
                <input
                  type="checkbox"
                  formControlName="remember"
                  id="acceptTerms"
                  class="form-check-input"
                />
              </div>
              <div class="text">
                <label for="acceptTerms" class="form-check-label">
                  {{ "SIGN_IN_REMEMBER_ME" | translate }}
                </label>
              </div>
            </div>
          </form>
          <button
            type="submit"
            class="button"
            (click)="onSubmit()"
            [disabled]="!loginForm.valid"
          >
            <div class="text">{{ "SIGN_IN_BUTTON" | translate }}</div>
          </button>
        </div>
        <div class="seperator">{{ "SIGN_UP_OPTIONS_OR" | translate }}</div>
        <asl-google-signin-button
          type="standard"
          size="large"
          [width]="322"
        ></asl-google-signin-button>
        <div class="seperator">{{ "SIGN_UP_OPTIONS_OR" | translate }}</div>
        <div class="sso-button" (click)="signInWithFacebook()">
          <div class="button-image">
            <img src="assets/icons/facebook.svg" alt="" />
          </div>
          <div class="text">{{ "SIGN_IN_FACEBOOK" | translate }}</div>
        </div>
        <div class="sign-up">
          <div class="text">{{ "SIGN_IN_NO_ACCOUNT" | translate }}</div>
          <div class="link" (click)="navigateToSignUpOptions()">
            {{ "SIGN_IN_CREATE_ONE" | translate }}
          </div>
        </div>
        <div class="help">
          <a href="{{ frontOfficeURL }}/contact">
            {{ "SIGN_IN_NEED_HELP" | translate }}
          </a>
        </div>
      </div>
      <div class="first-col">
        <div class="image">
          <img src="../../../assets/icons/breello-sign-up.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

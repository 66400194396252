<div
  class="navigation-bar"
  [ngClass]="{
    scrolled: scrollingPosition !== 0,
    light: isLight,
    'dropdown-is-opened': dropdownIsOpened,
  }"
>
  <div class="logo">
    <a [href]="frontOfficeURL">
      <img src="assets/icons/logo-light.svg" alt="" class="logo-light" />
      <img src="assets/icons/logo-dark.svg" alt="" class="logo-dark" />
    </a>
  </div>
  <div class="navigation-buttons">
    <a
      class="button-border"
      *ngIf="!addProperty"
      [routerLink]="'/add-property'"
    >
      <div class="text">{{ "NAVIGATION_BAR_ADD_PROPERTY" | translate }}</div>
      <div class="svg">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#icons-add'"
          [width]="'20px'"
          [height]="'20px'"
        ></svg-icon-sprite>
      </div>
    </a>
    <a class="button properties" [routerLink]="'/my-properties'">
      <div class="text">{{ "NAVIGATION_BAR_MY_PROPERTIES" | translate }}</div>
    </a>
    <div class="question-mark">
      <a href="{{ frontOfficeURL }}/contact" target="_blank">
        <svg-icon-sprite
          [src]="'assets/sprites/sprite.svg#question_outline'"
          [width]="'20px'"
          [height]="'20px'"
        ></svg-icon-sprite>
      </a>
    </div>
    <!-- <div class="button">
      <div class="text">
        En
        <div class="icon">
          <svg-icon-sprite
            [src]="'assets/sprites/sprite.svg#down'"
            [width]="'10px'"
            [height]="'20px'"
          ></svg-icon-sprite>
        </div>
      </div>
    </div> -->
    <div
      ngbDropdown
      #dropdown="ngbDropdown"
      (openChange)="dropdownIsOpened = $event"
    >
      <button class="btn btn-primary basic" type="button" ngbDropdownToggle>
        <div class="profile">
          <img src="assets/images/avatar.jpg" />
        </div>
      </button>
      <ul ngbDropdownMenu>
        <div ngbDropdownItem class="profile-email">
          {{ email }}
        </div>
        <li ngbDropdownItem>
          <a [routerLink]="'/settings'">
            {{ "NAVIGATION_BAR_SETTINGS" | translate }}</a
          >
        </li>
        <li ngbDropdownItem class="logout" (click)="logout()">
          <a> {{ "NAVIGATION_BAR_LOGOUT" | translate }}</a>
        </li>
      </ul>
    </div>
  </div>
</div>
<div
  class="navigation-bar-mobile"
  [ngClass]="{
    light: isLight,
    slidingMenu: showSlidingMenu,
    scrolled: scrollingPosition !== 0,
  }"
>
  <div class="visible-menu">
    <div class="logo" *ngIf="!showSlidingMenu">
      <a [href]="frontOfficeURL">
        <img src="assets/icons/logo-light.svg" alt="" class="logo-light" />
        <img src="assets/icons/logo-dark.svg" alt="" class="logo-dark" />
      </a>
    </div>
    <div
      class="icon"
      (click)="showSlidingMenu = !showSlidingMenu"
      *ngIf="!showSlidingMenu"
    >
      <svg-icon-sprite
        [src]="'assets/sprites/sprite.svg#navigational-menu'"
        [width]="'24px'"
        [height]="'24px'"
      ></svg-icon-sprite>
    </div>
  </div>
  <div
    class="sliding-menu"
    [ngClass]="{ open: showSlidingMenu, close: !showSlidingMenu }"
  >
    <app-sliding-menu
      [loggedIn]="true"
      (menuClosed)="closeMenu()"
    ></app-sliding-menu>
  </div>
</div>

<app-navigation-bar-logged-in [isLight]="true"></app-navigation-bar-logged-in>
<div class="mobile-sticky-top-bar">
  <div class="cta-button" (click)="navigateToAddProperty()">
    <div class="text">{{ "NAVIGATION_BAR_ADD_PROPERTY" | translate }}</div>
  </div>
</div>
<div class="my-properties">
  <div class="header">
    <div class="title">
      {{ "MY_PROPERTIES_TITLE" | translate }}
    </div>
    <div class="subtitle">
      {{ "MY_PROPERTIES_SUBTITLE" | translate }}
    </div>
  </div>
  <div
    class="no-properties"
    *ngIf="properties == null || properties.length === 0"
  >
    {{ "MY_PROPERTIES_EMPTY" | translate }}
  </div>
  <div class="property" *ngFor="let property of properties">
    <app-single-property [property]="property"></app-single-property>
  </div>
</div>
<app-footer></app-footer>

import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  SocialAuthService,
  SocialUser,
} from '@abacritt/angularx-social-login';
import { first, takeUntil } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import isEqual from 'lodash-es/isEqual';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-sign-up-options',
  templateUrl: './sign-up-options.component.html',
  styleUrls: ['./sign-up-options.component.scss'],
})
export class SignUpOptionsComponent implements OnInit, OnDestroy {
  // Public/Private properties.
  private currentSocialUser?: SocialUser;
  public frontOfficeURL: string;
  private destroy$ = new Subject<void>();

  constructor(
    private router: Router,
    private accountService: AccountService,
    private authService: SocialAuthService,
    private $gaService: GoogleAnalyticsService,
  ) {
    this.frontOfficeURL = environment.frontOfficeURL;
  }

  public ngOnInit(): void {
    // Listens for social logins.
    this.socialLoginListener();
  }

  private socialLoginListener(): void {
    this.authService.authState.pipe(takeUntil(this.destroy$)).subscribe((user) => {
      if (user == null) {
        return;
      }

      if (
        this.currentSocialUser != null &&
        isEqual(this.currentSocialUser, user)
      ) {
        return;
      }

      this.currentSocialUser = user;

      if (user.provider === GoogleLoginProvider.PROVIDER_ID) {
        this.$gaService.event('SignUp-GoogleOption-CTA', 'Click', 'SignUp-CTA');
        this.accountService
          .signInGoogle(user.idToken)
          .pipe(first())
          .subscribe({
            next: () => {
              this.router.navigate(['/my-properties']);
            },
            error: () => { },
          });
      } else if (user.provider === FacebookLoginProvider.PROVIDER_ID) {
        this.accountService
          .signInFacebook(user.authToken)
          .pipe(first())
          .subscribe({
            next: () => {
              this.router.navigate(['/my-properties']);
            },
            error: () => { },
          });
      }
    });
  }


  /**
   * Navigates user to sign in page.
   */
  public navigateToSignIn(): void {
    this.router.navigate(['/sign-in']);
    window.scroll(0, 0);
  }

  /**
   * Navigates user to sign up page.
   */
  public navigateToSignUp(): void {
    this.$gaService.event('SignUp-EmailOption-CTA', 'Click', 'SignUp-CTA');
    this.router.navigate(['/sign-up']);
    window.scroll(0, 0);
  }

  /**
   *
   */
  public signInWithFacebook(): void {
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}

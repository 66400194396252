import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import {
  HttpClient,
  HttpClientModule,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { SignInComponent } from './components/sign-in/sign-in.component';
import { SignUpOptionsComponent } from './components/sign-up-options/sign-up-options.component';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { MyPropertiesComponent } from './components/my-properties/my-properties.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { PropertyComponent } from './components/property/property.component';
import { AccountService } from './services/account.service';
import { AppInitializer } from './helpers/app-initializer';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import {
  FacebookLoginProvider,
  GoogleLoginProvider,
  GoogleSigninButtonModule,
  SocialAuthServiceConfig,
  SocialLoginModule,
} from '@abacritt/angularx-social-login';
import { ChipsModule } from 'primeng/chips';
import { InputMaskModule } from 'primeng/inputmask';
import { ToastModule } from 'primeng/toast';
import { FileUploadModule } from 'primeng/fileupload';
import { LeafletModule } from '@asymmetrik/ngx-leaflet';
import { SignUpComponent } from './components/sign-up/sign-up.component';
import { SignUpModalComponent } from './components/sign-up-modal/sign-up-modal.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { GenericModalComponent } from './components/generic-modal/generic-modal.component';
import { SettingsComponent } from './components/settings/settings.component';
import { UrlPipe } from './pipes/url.pipe';
import {
  NgxGoogleAnalyticsModule,
  NgxGoogleAnalyticsRouterModule,
} from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import { RouterModule } from '@angular/router';

// AoT requires an exported function for factories.
/**
 * HttpLoaderFactory
 * @param {HttpClient} http The HttpClient
 * @returns {TranslateHttpLoader} The response
 */
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    AppComponent,
    SignInComponent,
    SignUpOptionsComponent,
    MyPropertiesComponent,
    PropertyComponent,
    SignUpComponent,
    SignUpModalComponent,
    ForgotPasswordComponent,
    GenericModalComponent,
    SettingsComponent,
    UrlPipe,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IconSpriteModule,
    SharedModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot({
      defaultLanguage: 'el',
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ReactiveFormsModule,
    NgbModule,
    SocialLoginModule,
    GoogleSigninButtonModule,
    ChipsModule,
    InputMaskModule,
    FileUploadModule,
    ToastModule,
    LeafletModule,
    NgxGoogleAnalyticsModule.forRoot('G-71J46Z8N4R'),
    NgxGoogleAnalyticsRouterModule,
    RouterModule,
  ],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: AppInitializer,
      multi: true,
      deps: [AccountService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleClientId),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.facebookClientId),
          },
        ],
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [AppComponent],
  exports: [
    SignInComponent,
    SignUpOptionsComponent,
    MyPropertiesComponent,
    PropertyComponent,
    SignUpModalComponent,
    GenericModalComponent,
  ],
})
export class AppModule {}

<p-toast [baseZIndex]="10001"></p-toast>

<app-navigation-bar-logged-in
  [isLight]="true"
  [addProperty]="true"
></app-navigation-bar-logged-in>

<div class="property">
  <div class="top-bar" [ngClass]="{ scrolled: scrollingPosition >= 100 }">
    <div class="top-bar-content">
      <div class="top-bar-info">
        <div class="top-bar-info-title">
          <ng-container *ngIf="!editMode">
            {{ "ADD_PROPERTY_NEW_TITLE" | translate }}
          </ng-container>
          <ng-container *ngIf="editMode">
            {{ "ADD_PROPERTY_EDIT_TITLE" | translate }}
          </ng-container>
        </div>
        <div class="top-bar-info-subtitle">
          <ng-container *ngIf="!editMode">
            {{ "ADD_PROPERTY_NEW_SUBTITLE" | translate }}
          </ng-container>
          <span
            *ngIf="
              editMode &&
              propertyForm.controls.publishState.value ===
                PublishState.Unpublished
            "
            [innerHtml]="'ADD_PROPERTY_NEW_SUBTITLE_UNPUBLISHED' | translate"
          >
          </span>
          <span
            *ngIf="
              editMode &&
              propertyForm.controls.publishState.value === PublishState.Pending
            "
            [innerHtml]="'ADD_PROPERTY_NEW_SUBTITLE_PENDING' | translate"
          >
          </span>
          <span
            *ngIf="
              editMode &&
              propertyForm.controls.publishState.value ===
                PublishState.Published
            "
            [innerHtml]="'ADD_PROPERTY_NEW_SUBTITLE_PUBLISHED' | translate"
          >
          </span>
          <div
            *ngIf="
              editMode &&
              propertyForm.controls.publishState.value ===
                PublishState.Published
            "
          >
            <span
              class="platform-link"
              *ngFor="let platform of platforms; let last = last"
            >
              {{ platform.name }}<span *ngIf="!last">, </span>
            </span>
          </div>
        </div>
      </div>
      <div class="top-bar-buttons">
        <div
          class="top-bar-buttons-unpublish custom-tooltip"
          *ngIf="
            editMode &&
            propertyForm.controls.publishState.value !==
              PublishState.Unpublished
          "
          (click)="unpublishProperty()"
        >
          <span class="tooltiptext right">
            {{ "ADD_PROPERTY_UNPUBLISH_TOOLTIP" | translate }}
          </span>
          {{ "ADD_PROPERTY_UNPUBLISH" | translate }}
        </div>
        <div
          class="top-bar-buttons-publish"
          *ngIf="
            !editMode ||
            propertyForm.controls.publishState.value ===
              PublishState.Unpublished ||
            (isAdmin &&
              propertyForm.controls.publishState.value === PublishState.Pending)
          "
        >
          <ng-container *ngTemplateOutlet="submitButton"> </ng-container>
        </div>
        <div
          class="top-bar-buttons-save"
          *ngIf="
            !editMode ||
            propertyForm.controls.publishState.value ===
              PublishState.Unpublished
          "
        >
          <button
            class="save custom-tooltip"
            (click)="onSubmit(false)"
            [ngClass]="{ 'custom-tooltip': !propertyForm.pristine }"
            [disabled]="propertyForm.pristine || saving"
          >
            <span class="tooltiptext right" *ngIf="!propertyForm.pristine">
              {{ "ADD_PROPERTY_SAVE_TOOLTIP" | translate }}
            </span>
            {{ "ADD_PROPERTY_SAVE" | translate }}
            <div class="lds-dual-ring" *ngIf="saving"></div>
          </button>
        </div>
        <div
          class="top-bar-buttons-update"
          *ngIf="
            editMode &&
            !(
              isAdmin &&
              propertyForm.controls.publishState.value === PublishState.Pending
            ) &&
            propertyForm.controls.publishState.value !==
              PublishState.Unpublished
          "
        >
          <button
            class="update"
            (click)="onSubmit(true); saveCta()"
            [disabled]="propertyForm.invalid || propertyForm.pristine"
          >
            {{ "ADD_PROPERTY_UPDATE" | translate }}
            <div class="lds-dual-ring" *ngIf="saving"></div>
          </button>
        </div>
        <div
          ngbDropdown
          #dropdown="ngbDropdown"
          [placement]="'bottom-end'"
          *ngIf="editMode"
        >
          <button
            class="btn btn-primary dropdown-toggle basic"
            type="button"
            ngbDropdownToggle
          >
            <svg-icon-sprite
              [src]="'assets/sprites/sprite.svg#vertical'"
              [width]="'20px'"
              [height]="'20px'"
            ></svg-icon-sprite>
          </button>
          <ul ngbDropdownMenu>
            <li ngbDropdownItem (click)="delete()">
              <a> {{ "ADD_PROPERTY_DELETE" | translate }}</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="add-property">
    <form [formGroup]="propertyForm">
      <div class="section">
        <div class="panel">
          <div class="title">
            {{ "ADD_PROPERTY_BASIC_ATTRIBUTES" | translate }}
          </div>
          <div class="main-content">
            <div class="attribute small">
              <div class="label">
                {{ "ADD_PROPERTY_RENT_BUY" | translate }}
                <span class="required"> * </span>
              </div>
              <div class="input">
                <div ngbDropdown #dropdown="ngbDropdown">
                  <button
                    class="btn btn-primary dropdown-toggle basic small"
                    type="button"
                    ngbDropdownToggle
                    [ngClass]="{
                      'error-input':
                        propertyForm.get('action')?.invalid &&
                        (propertyForm.get('action')?.dirty ||
                          propertyForm.get('action')?.touched),
                      valid: propertyForm.controls.action.value != null,
                    }"
                  >
                    <div class="dropdown-toggle-content">
                      <ng-container
                        *ngIf="propertyForm.controls.action.value != null"
                      >
                        {{
                          "ACTION_" + propertyForm.controls.action.value
                            | translate
                        }}
                      </ng-container>
                      <ng-container
                        *ngIf="propertyForm.controls.action.value == null"
                      >
                        {{ "ACTION_SELECT" | translate }}
                      </ng-container>
                      <span class="caret"></span>
                    </div>
                  </button>
                  <ul ngbDropdownMenu>
                    <li
                      *ngFor="let item of actions"
                      ngbDropdownItem
                      (click)="
                        propertyForm.markAsDirty();
                        propertyForm.patchValue({ action: item })
                      "
                    >
                      <a> {{ "ACTION_" + item | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="error" *ngIf="errors && errors[PropertyError.ACTION]">
                {{ errors[PropertyError.ACTION] | translate }}
              </div>
            </div>
            <div class="attribute small">
              <div class="label">
                {{ "ADD_PROPERTY_CATEGORY" | translate }}
                <span class="required"> * </span>
              </div>
              <div class="input">
                <div ngbDropdown #dropdown="ngbDropdown">
                  <button
                    class="btn btn-primary dropdown-toggle basic small"
                    type="button"
                    ngbDropdownToggle
                    [ngClass]="{
                      'error-input':
                        propertyForm.get('type')?.invalid &&
                        (propertyForm.get('type')?.dirty ||
                          propertyForm.get('type')?.touched),
                      valid: propertyForm.controls.type.value != null,
                    }"
                  >
                    <div class="dropdown-toggle-content">
                      <ng-container
                        *ngIf="propertyForm.controls.type.value != null"
                      >
                        {{
                          "TYPE_" + propertyForm.controls.type.value | translate
                        }}
                      </ng-container>
                      <ng-container
                        *ngIf="propertyForm.controls.type.value == null"
                      >
                        {{ "TYPE_SELECT" | translate }}
                      </ng-container>
                      <span class="caret"></span>
                    </div>
                  </button>
                  <ul ngbDropdownMenu>
                    <li
                      *ngFor="let item of types"
                      ngbDropdownItem
                      (click)="
                        propertyForm.markAsDirty();
                        filterSubtypes(item);
                        propertyForm.patchValue({ type: item.toString() })
                      "
                    >
                      <a> {{ "TYPE_" + item.toString() | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="error" *ngIf="errors && errors[PropertyError.TYPE]">
                {{ errors[PropertyError.TYPE] | translate }}
              </div>
            </div>
            <div class="attribute small">
              <div class="label">
                {{ "ADD_PROPERTY_SUBCATEGORY" | translate }}
              </div>
              <div class="input">
                <div
                  ngbDropdown
                  #dropdown="ngbDropdown"
                  [ngClass]="{
                    disabled: propertyForm.controls.type.value == null,
                  }"
                >
                  <button
                    class="btn btn-primary dropdown-toggle basic small"
                    type="button"
                    ngbDropdownToggle
                    [disabled]="propertyForm.controls.type.value == null"
                    [ngClass]="{
                      valid: propertyForm.controls.subType.value != null,
                    }"
                  >
                    <ng-container
                      *ngIf="propertyForm.controls.subType.value != null"
                    >
                      {{
                        "SUBTYPE_" + propertyForm.controls.subType.value
                          | translate
                      }}
                    </ng-container>
                    <ng-container
                      *ngIf="propertyForm.controls.subType.value == null"
                    >
                      {{ "SUBTYPE_SELECT" | translate }}
                    </ng-container>
                    <span class="caret"></span>
                  </button>
                  <ul ngbDropdownMenu>
                    <li
                      *ngFor="let item of filteredsubTypes"
                      ngbDropdownItem
                      (click)="
                        propertyForm.markAsDirty();
                        propertyForm.patchValue({ subType: item })
                      "
                    >
                      <a> {{ "SUBTYPE_" + item | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="error"
                *ngIf="errors && errors[PropertyError.SUBTYPE]"
              >
                {{ errors[PropertyError.SUBTYPE] | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="dialog">
          {{ "ADD_PROPERTY_SIDENOTE_1" | translate }}
        </div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">{{ "ADD_PROPERTY_ATTRIBUTES" | translate }}</div>
          <div class="main-content">
            <div class="attribute medium">
              <div class="label">
                <div class="icon">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#price'"
                    [width]="'20px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
                {{ "ADD_PROPERTY_PRICE" | translate }}
                <span class="required"> * </span>
              </div>
              <div class="input">
                <div class="input-group medium">
                  <input
                    type="number"
                    onkeydown="javascript: return event.keyCode === 8 ||
event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                    class="form-control"
                    [placeholder]="'ADD_PROPERTY_PRICE_PLACEHOLDER' | translate"
                    formControlName="price"
                    [ngClass]="{
                      'error-input':
                        propertyForm.get('price')?.invalid &&
                        (propertyForm.get('price')?.dirty ||
                          propertyForm.get('price')?.touched),
                    }"
                  />
                  <div
                    class="input-group-append"
                    *ngIf="
                      !(
                        propertyForm.get('price')?.invalid &&
                        (propertyForm.get('price')?.dirty ||
                          propertyForm.get('price')?.touched)
                      )
                    "
                  >
                    €
                  </div>
                  <div
                    class="error-icon"
                    *ngIf="
                      propertyForm.get('price')?.invalid &&
                      (propertyForm.get('price')?.dirty ||
                        propertyForm.get('price')?.touched)
                    "
                  >
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#alert'"
                      [width]="'16px'"
                      [height]="'16px'"
                    ></svg-icon-sprite>
                  </div>
                </div>
              </div>
              <div
                class="error"
                *ngIf="
                  propertyForm.get('price')?.invalid &&
                  (propertyForm.get('price')?.dirty ||
                    propertyForm.get('price')?.touched)
                "
              >
                <ng-container
                  *ngIf="propertyForm.get('price')?.errors?.required"
                >
                  {{ "PRICE_ERROR_REQUIRED" | translate }}
                </ng-container>
                <ng-container *ngIf="propertyForm.get('price')?.errors?.min">
                  {{ "PRICE_ERROR_MIN" | translate }}
                </ng-container>
              </div>
              <div class="error" *ngIf="errors && errors[PropertyError.ROOMS]">
                {{ errors[PropertyError.ROOMS] | translate }}
              </div>
            </div>
            <div class="attribute medium">
              <div class="label">
                <div class="icon">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#rooms'"
                    [width]="'20px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
                {{ "ADD_PROPERTY_ROOMS" | translate }}
                <span class="required"> * </span>
              </div>
              <div class="input">
                <div class="input-group buttons">
                  <div
                    class="input-group-decrease"
                    (click)="propertyForm.markAsDirty(); decrease()"
                    [ngClass]="{ zero: propertyForm.get('rooms')?.value <= 0 }"
                  >
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#icons-minus'"
                      [width]="'24px'"
                      [height]="'24px'"
                    ></svg-icon-sprite>
                  </div>
                  <input
                    type="number"
                    onkeydown="javascript: return event.keyCode === 8 ||
event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                    class="form-control buttons rooms"
                    placeholder="1"
                    formControlName="rooms"
                    [ngClass]="{
                      'error-input':
                        propertyForm.get('rooms')?.invalid &&
                        (propertyForm.get('rooms')?.dirty ||
                          propertyForm.get('rooms')?.touched),
                    }"
                  />
                  <div
                    class="input-group-increase"
                    (click)="propertyForm.markAsDirty(); increase()"
                  >
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#icons-add'"
                      [width]="'24px'"
                      [height]="'24px'"
                    ></svg-icon-sprite>
                  </div>
                </div>
              </div>
              <div
                class="error"
                *ngIf="
                  propertyForm.get('rooms')?.invalid &&
                  (propertyForm.get('rooms')?.dirty ||
                    propertyForm.get('rooms')?.touched)
                "
              >
                <ng-container
                  *ngIf="propertyForm.get('rooms')?.errors?.required"
                >
                  {{ "ROOMS_ERROR_REQUIRED" | translate }}
                </ng-container>
                <ng-container *ngIf="propertyForm.get('rooms')?.errors?.min">
                  {{ "ROOMS_ERROR_MIN" | translate }}
                </ng-container>
              </div>
            </div>
            <div class="attribute last medium">
              <div class="label">
                <div class="icon">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#size'"
                    [width]="'20px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
                {{ "ADD_PROPERTY_SIZE" | translate }}
                <span class="required"> * </span>
              </div>
              <div class="input">
                <div class="input-group medium">
                  <input
                    type="number"
                    onkeydown="javascript: return event.keyCode === 8 ||
event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                    class="form-control"
                    [placeholder]="'ADD_PROPERTY_SIZE_PLACEHOLDER' | translate"
                    formControlName="size"
                    [ngClass]="{
                      'error-input':
                        propertyForm.get('size')?.invalid &&
                        (propertyForm.get('size')?.dirty ||
                          propertyForm.get('size')?.touched),
                    }"
                  />
                  <div
                    class="input-group-append"
                    *ngIf="
                      !(
                        propertyForm.get('size')?.invalid &&
                        (propertyForm.get('size')?.dirty ||
                          propertyForm.get('size')?.touched)
                      )
                    "
                  >
                    m2
                  </div>
                  <div
                    class="error-icon"
                    *ngIf="
                      propertyForm.get('size')?.invalid &&
                      (propertyForm.get('size')?.dirty ||
                        propertyForm.get('size')?.touched)
                    "
                  >
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#alert'"
                      [width]="'16px'"
                      [height]="'16px'"
                    ></svg-icon-sprite>
                  </div>
                </div>
              </div>
              <div
                class="error"
                *ngIf="
                  propertyForm.get('size')?.invalid &&
                  (propertyForm.get('size')?.dirty ||
                    propertyForm.get('size')?.touched)
                "
              >
                <ng-container
                  *ngIf="propertyForm.get('size')?.errors?.required"
                >
                  {{ "SIZE_ERROR_REQUIRED" | translate }}
                </ng-container>
                <ng-container *ngIf="propertyForm.get('size')?.errors?.min">
                  {{ "SIZE_ERROR_MIN" | translate }}
                </ng-container>
              </div>
              <div class="error" *ngIf="errors && errors[PropertyError.SIZE]">
                {{ errors[PropertyError.SIZE] | translate }}
              </div>
            </div>
            <div class="attribute medium">
              <div class="label">
                <div class="icon">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#floor'"
                    [width]="'20px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
                {{ "ADD_PROPERTY_FLOOR_FROM" | translate }}
              </div>
              <div class="input">
                <div ngbDropdown #dropdown="ngbDropdown">
                  <button
                    class="btn btn-primary dropdown-toggle medium"
                    type="button"
                    ngbDropdownToggle
                    [ngClass]="{
                      valid: propertyForm.controls.floor.value,
                    }"
                  >
                    <div class="dropdown-toggle-content">
                      <ng-container
                        *ngIf="propertyForm.controls.floor.value != null"
                      >
                        {{
                          "FLOOR_" + propertyForm.controls.floor.value
                            | translate
                        }}<span class="caret"></span>
                      </ng-container>
                      <ng-container
                        *ngIf="propertyForm.controls.floor.value == null"
                      >
                        {{ "FLOOR_SELECT" | translate
                        }}<span class="caret"></span>
                      </ng-container>
                    </div>
                  </button>
                  <ul ngbDropdownMenu>
                    <li
                      *ngFor="let item of floors"
                      ngbDropdownItem
                      (click)="
                        propertyForm.markAsDirty();
                        propertyForm.patchValue({ floor: item })
                      "
                    >
                      <a> {{ "FLOOR_" + item | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="error" *ngIf="errors && errors[PropertyError.FLOOR]">
                {{ errors[PropertyError.FLOOR] | translate }}
              </div>
            </div>
            <div class="attribute medium">
              <div class="label">
                <div class="icon">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#floor'"
                    [width]="'20px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
                {{ "ADD_PROPERTY_FLOOR_TO" | translate }}
                <div class="question-mark custom-tooltip">
                  <span class="tooltiptext right">
                    {{ "FLOOR_TO_INFO_TOOLTIP" | translate }}
                  </span>
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#question_outline'"
                    [width]="'20px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
              </div>
              <div class="input">
                <div ngbDropdown #dropdown="ngbDropdown">
                  <button
                    class="btn btn-primary dropdown-toggle medium"
                    type="button"
                    ngbDropdownToggle
                    [ngClass]="{
                      valid: propertyForm.controls.floorTo.value != null,
                    }"
                  >
                    <div class="dropdown-toggle-content">
                      <ng-container
                        *ngIf="propertyForm.controls.floorTo.value != null"
                      >
                        {{
                          "FLOOR_" + propertyForm.controls.floorTo.value
                            | translate
                        }}<span class="caret"></span>
                      </ng-container>
                      <ng-container
                        *ngIf="propertyForm.controls.floorTo.value == null"
                      >
                        {{ "FLOOR_SELECT" | translate }}
                        <span class="caret"></span>
                      </ng-container>
                    </div>
                  </button>
                  <ul ngbDropdownMenu>
                    <li
                      *ngFor="let item of floors"
                      ngbDropdownItem
                      (click)="
                        propertyForm.markAsDirty();
                        propertyForm.patchValue({ floorTo: item })
                      "
                    >
                      <a> {{ "FLOOR_" + item | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="error"
                *ngIf="errors && errors[PropertyError.FLOOR_TO]"
              >
                {{ errors[PropertyError.FLOOR_TO] | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="dialog">
          {{ "ADD_PROPERTY_SIDENOTE_2" | translate }}
        </div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">{{ "ADD_PROPERTY_ADDRESS" | translate }}</div>
          <div class="main-content address">
            <div class="label">
              {{ "ADD_PROPERTY_ADDRESS_SHOW" | translate }}
            </div>
            <div class="radio-button-group">
              <div
                class="radio-button"
                (click)="
                  propertyForm.markAsDirty();
                  propertyForm.patchValue({
                    exactLocation: propertyForm.controls.exactLocation.value
                      ? propertyForm.controls.exactLocation.value
                      : !propertyForm.controls.exactLocation.value,
                  });
                  updateLayer(currentLat, currentLng)
                "
              >
                <div
                  class="icon"
                  [ngClass]="{
                    selected: propertyForm.controls.exactLocation.value,
                  }"
                >
                  <div class="dot"></div>
                </div>
                <div class="text">
                  {{ "ADD_PROPERTY_ADDRESS_EXACT" | translate }}
                </div>
              </div>
              <div
                class="radio-button"
                (click)="
                  propertyForm.markAsDirty();
                  propertyForm.patchValue({
                    exactLocation: !propertyForm.controls.exactLocation.value
                      ? propertyForm.controls.exactLocation.value
                      : !propertyForm.controls.exactLocation.value,
                  });
                  updateLayer(currentLat, currentLng)
                "
              >
                <div
                  class="icon"
                  [ngClass]="{
                    selected: !propertyForm.controls.exactLocation.value,
                  }"
                >
                  <div class="dot"></div>
                </div>
                <div class="text">
                  {{ "ADD_PROPERTY_ADDRESS_APPROXIMATE" | translate }}
                </div>
              </div>
            </div>
            <div class="label">
              {{ "ADD_PROPERTY_PROPERTY_ADDRESS" | translate }}
              <span class="required"> * </span>
            </div>
            <div>
              <input
                id="property-address"
                type="text"
                class="input address"
                formControlName="address"
                placeholder="{{
                  'ADD_PROPERTY_ADDRESS_PLACEHOLDER' | translate
                }}"
                [ngClass]="{
                  'error-input':
                    propertyForm.get('address')?.invalid &&
                    (propertyForm.get('address')?.dirty ||
                      propertyForm.get('address')?.touched),
                }"
              />
            </div>
            <div
              class="error"
              *ngIf="
                propertyForm.get('address')?.invalid &&
                (propertyForm.get('address')?.dirty ||
                  propertyForm.get('address')?.touched)
              "
            >
              <ng-container
                *ngIf="propertyForm.get('address')?.errors?.required"
              >
                {{ "ADDRESS_ERROR_REQUIRED" | translate }}
              </ng-container>
            </div>
            <div
              class="map"
              leaflet
              [leafletOptions]="mapOptions"
              [leafletLayers]="layers"
              [(leafletCenter)]="center"
              [(leafletZoom)]="zoom"
              (leafletZoomChange)="buildMapMarker()"
              (leafletClick)="propertyForm.markAsDirty(); mapClick($event)"
            ></div>
          </div>
        </div>
        <div class="dialog">
          {{ "ADD_PROPERTY_SIDENOTE_3" | translate }}
        </div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">{{ "ADD_PROPERTY_PHOTOS" | translate }}</div>
          <div class="main-content photos">
            <div class="label">
              {{ "ADD_PROPERTY_PROPERTY_PHOTOS" | translate }}
              <div class="label-description">
                {{ "ADD_PROPERTY_PROPERTY_PHOTOS_DESCRIPTION_1" | translate }}
              </div>
              <div class="label-description">
                {{ "ADD_PROPERTY_PROPERTY_PHOTOS_DESCRIPTION_2" | translate }}
              </div>
            </div>
            <div class="file-upload">
              <p-fileUpload
                styleClass="property-photos-upload"
                name="myfile[]"
                customUpload="true"
                multiple="multiple"
                accept="image/*"
                chooseIcon=""
                uploadIcon=""
                [chooseLabel]="'ADD_PROPERTY_PHOTOS_BROWSE' | translate"
                [showCancelButton]="false"
                [showUploadButton]="false"
                maxFileSize="4194304"
                (onSelect)="propertyForm.markAsDirty(); filesSelected($event)"
              >
                <ng-template pTemplate="file"> </ng-template>
                <ng-template pTemplate="content">
                  <div class="files">
                    <div
                      class="file"
                      *ngFor="let file of photos; let i = index"
                    >
                      <div class="file-remove" (click)="removeFile(i)">
                        <svg-icon-sprite
                          [src]="'assets/sprites/sprite.svg#close'"
                          [width]="'24px'"
                          [height]="'24px'"
                        ></svg-icon-sprite>
                      </div>
                      <img class="file-image" [src]="transformLink(file)" />
                    </div>
                    <div
                      class="file"
                      *ngFor="let file of existingPhotos; let i = index"
                    >
                      <div class="file-remove" (click)="removeExistingFile(i)">
                        <svg-icon-sprite
                          [src]="'assets/sprites/sprite.svg#close'"
                          [width]="'24px'"
                          [height]="'24px'"
                        ></svg-icon-sprite>
                      </div>
                      <img
                        class="file-image"
                        src="{{
                          api +
                            '/property/getImage/' +
                            photosPath +
                            '/' +
                            file.name
                        }}"
                      />
                    </div>
                  </div>
                </ng-template>
              </p-fileUpload>
            </div>
          </div>
        </div>
        <div
          class="dialog"
          [innerHTML]="'ADD_PROPERTY_SIDENOTE_4' | translate"
        ></div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">
            {{ "ADD_PROPERTY_MORE_ATTRIBUTES" | translate }}
          </div>
          <div class="main-content more">
            <div class="attribute medium single-line">
              <div class="label">
                <div class="icon">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#energy_class'"
                    [width]="'20px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
                {{ "ADD_PROPERTY_ENERGY_CLASS" | translate }}
              </div>
              <div class="input">
                <div ngbDropdown #dropdown="ngbDropdown">
                  <button
                    class="btn btn-primary dropdown-toggle medium"
                    type="button"
                    ngbDropdownToggle
                    [ngClass]="{
                      valid: propertyForm.controls.energyClass.value != null,
                    }"
                  >
                    <div class="dropdown-toggle-content">
                      <ng-container
                        *ngIf="propertyForm.controls.energyClass.value != null"
                      >
                        {{
                          "ENERGY_CLASS_" +
                            propertyForm.controls.energyClass.value | translate
                        }}
                      </ng-container>
                      <span class="caret"></span>
                    </div>
                  </button>
                  <ul ngbDropdownMenu>
                    <li
                      *ngFor="let item of energyClasses"
                      ngbDropdownItem
                      (click)="
                        propertyForm.markAsDirty();
                        propertyForm.patchValue({ energyClass: item })
                      "
                    >
                      <a> {{ "ENERGY_CLASS_" + item | translate }}</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="grouped-attributes first">
              <div class="attribute large">
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#protocol_number'"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "ADD_PROPERTY_PROTOCOL_NUMBER" | translate }}
                </div>
                <div>
                  <p-inputMask
                    formControlName="protocolNumber"
                    mask="(99999/999)"
                    slotChar="#"
                    placeholder="(#####/###)"
                    styleClass="custom-input-mask"
                  ></p-inputMask>
                </div>
                <div
                  class="error"
                  *ngIf="errors && errors[PropertyError.PROTOCOL_NUMBER]"
                >
                  {{ errors[PropertyError.PROTOCOL_NUMBER] | translate }}
                </div>
              </div>
              <div class="attribute large">
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#safety_number'"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "ADD_PROPERTY_SECURITY_NUMBER" | translate }}
                </div>
                <div>
                  <p-inputMask
                    formControlName="securityNumber"
                    mask="(*****-*****-*****-*)"
                    slotChar="_"
                    placeholder="(_____-_____-_____-_)"
                    styleClass="custom-input-mask"
                  ></p-inputMask>
                </div>
                <div
                  class="error"
                  *ngIf="errors && errors[PropertyError.SECURITY_NUMBER]"
                >
                  {{ errors[PropertyError.SECURITY_NUMBER] | translate }}
                </div>
              </div>
            </div>
            <div class="grouped-attributes second">
              <div class="attribute medium">
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#calendar_home'"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "ADD_PROPERTY_CONSTRUCTION_YEAR" | translate }}
                  <span class="required"> * </span>
                </div>
                <div>
                  <input
                    type="number"
                    onkeydown="javascript: return event.keyCode === 8 ||
event.keyCode === 46 ? true : !isNaN(Number(event.key))"
                    class="input medium"
                    placeholder="πχ. 1996"
                    formControlName="constructionYear"
                    [ngClass]="{
                      'error-input':
                        propertyForm.get('constructionYear')?.invalid &&
                        (propertyForm.get('constructionYear')?.dirty ||
                          propertyForm.get('constructionYear')?.touched),
                    }"
                  />
                </div>
                <div
                  class="error-icon"
                  *ngIf="
                    propertyForm.get('constructionYear')?.invalid &&
                    (propertyForm.get('constructionYear')?.dirty ||
                      propertyForm.get('constructionYear')?.touched)
                  "
                >
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#alert'"
                    [width]="'16px'"
                    [height]="'16px'"
                  ></svg-icon-sprite>
                </div>
                <div
                  class="error"
                  *ngIf="
                    propertyForm.get('constructionYear')?.invalid &&
                    (propertyForm.get('constructionYear')?.dirty ||
                      propertyForm.get('constructionYear')?.touched)
                  "
                >
                  <ng-container
                    *ngIf="
                      propertyForm.get('constructionYear')?.errors?.required
                    "
                  >
                    {{ "CONSTRUCTION_YEAR_ERROR_REQUIRED" | translate }}
                  </ng-container>
                  <ng-container
                    *ngIf="propertyForm.get('constructionYear')?.errors?.min"
                  >
                    {{ "CONSTRUCTION_YEAR_ERROR_MIN" | translate }}
                  </ng-container>
                  <ng-container
                    *ngIf="propertyForm.get('constructionYear')?.errors?.max"
                  >
                    {{ "CONSTRUCTION_YEAR_ERROR_MAX" | translate }}
                  </ng-container>
                </div>
              </div>
              <div class="attribute medium">
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#view'"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "ADD_PROPERTY_VIEW" | translate }}
                </div>
                <div class="input">
                  <div ngbDropdown #dropdown="ngbDropdown">
                    <button
                      class="btn btn-primary dropdown-toggle medium"
                      type="button"
                      ngbDropdownToggle
                      [ngClass]="{
                        valid: propertyForm.controls.view.value != null,
                      }"
                    >
                      <div class="dropdown-toggle-content">
                        <ng-container
                          *ngIf="propertyForm.controls.view.value != null"
                        >
                          {{
                            "VIEW_" + propertyForm.controls.view.value
                              | translate
                          }}
                          <span class="caret"></span>
                        </ng-container>
                        <ng-container
                          *ngIf="propertyForm.controls.view.value == null"
                        >
                          {{ "VIEW_SELECT" | translate }}
                          <span class="caret"></span>
                        </ng-container>
                      </div>
                    </button>
                    <ul ngbDropdownMenu>
                      <li
                        *ngFor="let item of views"
                        ngbDropdownItem
                        (click)="
                          propertyForm.markAsDirty();
                          propertyForm.patchValue({ view: item })
                        "
                      >
                        <a> {{ "VIEW_" + item | translate }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="error" *ngIf="errors && errors[PropertyError.VIEW]">
                  {{ errors[PropertyError.VIEW] | translate }}
                </div>
              </div>
              <div class="attribute medium last">
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#parking'"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "ADD_PROPERTY_PARKING" | translate }}
                </div>
                <div class="input">
                  <div ngbDropdown #dropdown="ngbDropdown">
                    <button
                      class="btn btn-primary dropdown-toggle medium"
                      type="button"
                      ngbDropdownToggle
                      [ngClass]="{
                        valid: propertyForm.controls.parking.value != null,
                      }"
                    >
                      <div class="dropdown-toggle-content">
                        <ng-container
                          *ngIf="propertyForm.controls.parking.value != null"
                        >
                          {{
                            "PARKING_" + propertyForm.controls.parking.value
                              | translate
                          }}
                          <span class="caret"></span>
                        </ng-container>
                        <ng-container
                          *ngIf="propertyForm.controls.parking.value == null"
                        >
                          {{ "PARKING_SELECT" | translate }}
                          <span class="caret"></span>
                        </ng-container>
                      </div>
                    </button>
                    <ul ngbDropdownMenu>
                      <li
                        *ngFor="let item of views"
                        ngbDropdownItem
                        (click)="
                          propertyForm.markAsDirty();
                          propertyForm.patchValue({ parking: item })
                        "
                      >
                        <a> {{ "PARKING_" + item | translate }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="error"
                  *ngIf="errors && errors[PropertyError.PARKING]"
                >
                  {{ errors[PropertyError.PARKING] | translate }}
                </div>
              </div>
              <div class="attribute medium">
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#heating_type'"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "ADD_PROPERTY_HEATING_TYPE" | translate }}
                </div>
                <div class="input">
                  <div ngbDropdown #dropdown="ngbDropdown">
                    <button
                      class="btn btn-primary dropdown-toggle medium"
                      type="button"
                      ngbDropdownToggle
                      [ngClass]="{
                        valid: propertyForm.controls.heatingType.value != null,
                      }"
                    >
                      <div class="dropdown-toggle-content">
                        <ng-container
                          *ngIf="
                            propertyForm.controls.heatingType.value != null
                          "
                        >
                          {{
                            "HEATING_TYPE_" +
                              propertyForm.controls.heatingType.value
                              | translate
                          }}
                          <span class="caret"></span>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            propertyForm.controls.heatingType.value == null
                          "
                        >
                          {{ "HEATING_TYPE_SELECT" | translate }}
                          <span class="caret"></span>
                        </ng-container>
                      </div>
                    </button>
                    <ul ngbDropdownMenu>
                      <li
                        *ngFor="let item of heatingTypes"
                        ngbDropdownItem
                        (click)="
                          propertyForm.markAsDirty();
                          propertyForm.patchValue({ heatingType: item })
                        "
                      >
                        <a> {{ "HEATING_TYPE_" + item | translate }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="error"
                  *ngIf="errors && errors[PropertyError.HEATING_TYPE]"
                >
                  {{ errors[PropertyError.HEATING_TYPE] | translate }}
                </div>
              </div>
              <div class="attribute medium">
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#heating_category'"
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "ADD_PROPERTY_HEATING_CATEGORY" | translate }}
                </div>
                <div class="input">
                  <div ngbDropdown #dropdown="ngbDropdown">
                    <button
                      class="btn btn-primary dropdown-toggle medium"
                      type="button"
                      ngbDropdownToggle
                      [ngClass]="{
                        valid:
                          propertyForm.controls.heatingCategory.value != null,
                      }"
                    >
                      <div class="dropdown-toggle-content">
                        <ng-container
                          *ngIf="
                            propertyForm.controls.heatingCategory.value != null
                          "
                        >
                          {{
                            "HEATING_CATEGORY_" +
                              propertyForm.controls.heatingCategory.value
                              | translate
                          }}
                          <span class="caret"></span>
                        </ng-container>
                        <ng-container
                          *ngIf="
                            propertyForm.controls.heatingCategory.value == null
                          "
                        >
                          {{ "HEATING_CATEGORY_SELECT" | translate }}
                          <span class="caret"></span>
                        </ng-container>
                      </div>
                    </button>
                    <ul ngbDropdownMenu>
                      <li
                        *ngFor="let item of heatingCategories"
                        ngbDropdownItem
                        (click)="
                          propertyForm.markAsDirty();
                          propertyForm.patchValue({ heatingCategory: item })
                        "
                      >
                        <a> {{ "HEATING_CATEGORY_" + item | translate }}</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  class="error"
                  *ngIf="errors && errors[PropertyError.HEATING_CATEGORY]"
                >
                  {{ errors[PropertyError.HEATING_CATEGORY] | translate }}
                </div>
              </div>
            </div>
            <div class="grouped-attributes margin">
              <div
                class="attribute radio-box"
                *ngFor="let item of extraAttributes"
                (click)="propertyForm.markAsDirty(); pushAttribute(item)"
              >
                <div
                  class="radio-box"
                  [ngClass]="{
                    checked:
                      propertyForm.controls.extraAttributes.value.indexOf(
                        item
                      ) !== -1,
                  }"
                >
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="'assets/sprites/sprite.svg#check'"
                      [width]="'12px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                </div>
                <div class="label">
                  <div class="icon">
                    <svg-icon-sprite
                      [src]="
                        'assets/sprites/sprite.svg#extra_attribute_' +
                        item.toString()
                      "
                      [width]="'20px'"
                      [height]="'20px'"
                    ></svg-icon-sprite>
                  </div>
                  {{ "EXTRA_ATTRIBUTE_" + item.toString() | translate }}
                </div>
              </div>
            </div>
            <div class="attribute single-line margin other">
              <div class="label">{{ "ADD_PROPERTY_OTHER" | translate }}</div>
              <div>
                <p-chips
                  formControlName="tags"
                  [separator]="','"
                  [placeholder]="'ADD_PROPERTY_OTHER_PLACEHOLDER' | translate"
                  [styleClass]="'custom-chips'"
                  [ngClass]="{
                    valid:
                      propertyForm.controls.tags.value != null &&
                      propertyForm.controls.tags.value.length > 0,
                  }"
                  [addOnBlur]="true"
                ></p-chips>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog">
          {{ "ADD_PROPERTY_SIDENOTE_5" | translate }}
        </div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">{{ "ADD_PROPERTY_DESCRIPTION" | translate }}</div>
          <div class="main-content description">
            <div class="attribute single-line">
              <div class="label">
                {{ "ADD_PROPERTY_DESCRIPTION_GREEK" | translate }}
                <div class="icon description">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#question'"
                    [width]="'16px'"
                    [height]="'16px'"
                  ></svg-icon-sprite>
                </div>
              </div>
              <div>
                <textarea
                  name="text"
                  class="input"
                  placeholder="{{
                    'ADD_PROPERTY_DESCRIPTION_PLACEHOLDER' | translate
                  }}"
                  formControlName="descriptionGr"
                  id=""
                  rows="8"
                  (blur)="
                    propertyForm.controls.descriptionGr.value != null &&
                      propertyForm.patchValue({
                        descriptionGr:
                          propertyForm.controls.descriptionGr.value.trim(),
                      })
                  "
                  pattern="[0-9Α-Ωα-ωίϊΐόάέύϋΰήώ ~!@;\-+_#$%^&*()\[\]\{\}]*"
                  [ngClass]="{
                    'error-input':
                      propertyForm.get('descriptionGr')?.invalid &&
                      (propertyForm.get('descriptionGr')?.dirty ||
                        propertyForm.get('descriptionGr')?.touched),
                    empty:
                      propertyForm.controls.descriptionEn.value == null ||
                      propertyForm.controls.descriptionEn.value.trim() === '',
                  }"
                ></textarea>
              </div>
              <div
                class="error"
                *ngIf="
                  propertyForm.get('descriptionGr')?.invalid &&
                  (propertyForm.get('descriptionGr')?.dirty ||
                    propertyForm.get('descriptionGr')?.touched)
                "
              >
                <ng-container
                  *ngIf="propertyForm.get('descriptionGr')?.errors?.required"
                >
                  {{ "DESCRIPTION_ERROR_REQUIRED" | translate }}
                </ng-container>
                <ng-container
                  *ngIf="!propertyForm.get('descriptionGr')?.errors?.required"
                >
                  {{ "DESCRIPTION_ERROR_GR_CHARACTERS" | translate }}
                </ng-container>
              </div>
            </div>
            <div class="attribute single-line">
              <div class="label">
                {{ "ADD_PROPERTY_DESCRIPTION_ENGLISH" | translate }}
                <div class="icon description">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#question'"
                    [width]="'16px'"
                    [height]="'16px'"
                  ></svg-icon-sprite>
                </div>
              </div>
              <div>
                <textarea
                  name="text"
                  class="input"
                  placeholder="{{
                    'ADD_PROPERTY_DESCRIPTION_ENGLISH_PLACEHOLDER' | translate
                  }}"
                  formControlName="descriptionEn"
                  id=""
                  pattern="[0-9a-zA-Z ~!@;\-+_#$%^&*()\[\]\{\}]*"
                  rows="8"
                  [ngClass]="{
                    'error-input':
                      propertyForm.get('descriptionEn')?.invalid &&
                      (propertyForm.get('descriptionEn')?.dirty ||
                        propertyForm.get('descriptionEn')?.touched),
                    empty:
                      propertyForm.controls.descriptionEn.value == null ||
                      propertyForm.controls.descriptionEn.value.trim() === '',
                  }"
                ></textarea>
              </div>
              <div
                class="error"
                *ngIf="
                  propertyForm.get('descriptionEn')?.invalid &&
                  (propertyForm.get('descriptionEn')?.dirty ||
                    propertyForm.get('descriptionEn')?.touched)
                "
              >
                <ng-container
                  *ngIf="propertyForm.get('descriptionEn')?.errors?.required"
                >
                  {{ "CONSTRUCTION_YEAR_ERROR_REQUIRED" | translate }}
                </ng-container>
                <ng-container
                  *ngIf="!propertyForm.get('descriptionEn')?.errors?.required"
                >
                  {{ "DESCRIPTION_ERROR_EN_CHARACTERS" | translate }}
                </ng-container>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog">
          {{ "ADD_PROPERTY_SIDENOTE_6" | translate }}
        </div>
      </div>
      <div class="section title">
        <div class="title contact">
          {{ "ADD_PROPERTY_CONTACT_BREELLO" | translate }}
        </div>
        <div class="text">
          {{ "ADD_PROPERTY_CONTACT_BREELLO_TEXT" | translate }}
        </div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">
            {{ "ADD_PROPERTY_CONTACT_DETAILS" | translate }}
          </div>
          <div class="main-content more">
            <div class="attribute full">
              <div class="label">
                {{ "ADD_PROPERTY_CONTACT_NAME" | translate }}
              </div>
              <input
                type="text"
                formControlName="contactName"
                [placeholder]="
                  'ADD_PROPERTY_CONTACT_NAME_PLACEHOLDER' | translate
                "
              />
              <div class="label">
                {{ "ADD_PROPERTY_EMAIL_ADDRESSES" | translate }}
                <span class="required"> * </span>
              </div>
              <div>
                <p-chips
                  formControlName="contactEmails"
                  [separator]="','"
                  [placeholder]="
                    'ADD_PROPERTY_EMAIL_ADDRESSES_PLACEHOLDER' | translate
                  "
                  [styleClass]="'custom-chips'"
                  [addOnBlur]="true"
                  (onAdd)="onContactEmailAdd($event)"
                  [ngClass]="{
                    'error-input':
                      propertyForm.get('contactEmails')?.invalid &&
                      (propertyForm.get('contactEmails')?.dirty ||
                        propertyForm.get('contactEmails')?.touched),
                    valid:
                      propertyForm.controls.contactEmails.value != null &&
                      propertyForm.controls.contactEmails.value.length > 0,
                  }"
                ></p-chips>
              </div>
              <div
                class="error"
                *ngIf="
                  propertyForm.get('contactEmails')?.invalid &&
                  (propertyForm.get('contactEmails')?.dirty ||
                    propertyForm.get('contactEmails')?.touched)
                "
              >
                <ng-container
                  *ngIf="propertyForm.get('contactEmails')?.errors?.required"
                >
                  {{ "CONTACT_EMAILS_ERROR_REQUIRED" | translate }}
                </ng-container>
              </div>
              <div
                class="error"
                *ngIf="errors && errors[PropertyError.CONTACT_EMAILS]"
              >
                {{ errors[PropertyError.CONTACT_EMAILS] | translate }}
              </div>
            </div>
            <div class="attribute full">
              <div class="label">
                {{ "ADD_PROPERTY_PHONE_NUMBERS" | translate }}
                <span class="required"> * </span>
              </div>
              <div>
                <p-chips
                  formControlName="contactPhones"
                  [separator]="','"
                  [placeholder]="
                    'ADD_PROPERTY_PHONE_NUMBERS_PLACEHOLDER' | translate
                  "
                  [styleClass]="'custom-chips'"
                  [addOnBlur]="true"
                  (onAdd)="onContactPhoneAdd($event)"
                  [ngClass]="{
                    'error-input':
                      propertyForm.get('contactPhones')?.invalid &&
                      (propertyForm.get('contactPhones')?.dirty ||
                        propertyForm.get('contactPhones')?.touched),
                    valid:
                      propertyForm.controls.contactPhones.value != null &&
                      propertyForm.controls.contactPhones.value.length > 0,
                  }"
                ></p-chips>
              </div>
              <div
                class="error"
                *ngIf="
                  propertyForm.get('contactPhones')?.invalid &&
                  (propertyForm.get('contactPhones')?.dirty ||
                    propertyForm.get('contactPhones')?.touched)
                "
              >
                <ng-container
                  *ngIf="propertyForm.get('contactPhones')?.errors?.required"
                >
                  {{ "CONTACT_PHONES_ERROR_REQUIRED" | translate }}
                </ng-container>
              </div>
              <div
                class="error"
                *ngIf="errors && errors[PropertyError.CONTACT_PHONES]"
              >
                {{ errors[PropertyError.CONTACT_PHONES] | translate }}
              </div>
            </div>
            <div class="attribute full">
              <div class="label">
                {{ "ADD_PROPERTY_CONTACT_PREFERENCES" | translate }}
              </div>
              <div class="radio-button-group vertical">
                <div
                  class="attribute more-radio-box"
                  *ngFor="let item of contactPreferences"
                  (click)="
                    propertyForm.markAsDirty(); pushContactPreference(item)
                  "
                >
                  <div
                    class="radio-box"
                    [ngClass]="{
                      checked:
                        propertyForm.controls.contactPreferences.value.indexOf(
                          item
                        ) !== -1,
                    }"
                  >
                    <div class="icon">
                      <svg-icon-sprite
                        [src]="'assets/sprites/sprite.svg#check'"
                        [width]="'12px'"
                        [height]="'20px'"
                      ></svg-icon-sprite>
                    </div>
                  </div>
                  <div class="label">
                    {{ "CONTACT_PREFERENCE_" + item.toString() | translate }}
                  </div>
                </div>
              </div>
              <div
                class="error"
                *ngIf="errors && errors[PropertyError.CONTACT_PREFERENCE]"
              >
                {{ errors[PropertyError.CONTACT_PREFERENCE] | translate }}
              </div>
            </div>
            <div class="title">
              {{ "ADD_PROPERTY_AVAILABILITY" | translate }}
            </div>

            <div class="attribute">
              <div class="label">
                {{ "ADD_PROPERTY_TIME_PREFERENCES" | translate }}
              </div>
              <div class="radio-button-group vertical no-border">
                <div
                  class="radio-button"
                  *ngFor="let item of availabilityTypes"
                  (click)="
                    propertyForm.markAsDirty();
                    preselectAvailabilityHours();
                    propertyForm.patchValue({
                      availabilityType: item,
                    })
                  "
                >
                  <div
                    class="icon"
                    [ngClass]="{
                      selected:
                        propertyForm.controls.availabilityType.value === item,
                    }"
                  >
                    <div class="dot"></div>
                  </div>
                  <div class="text">
                    {{ "AVAILABILITY_TYPE_" + item | translate }}
                  </div>
                </div>
              </div>
              <div
                class="error"
                *ngIf="errors && errors[PropertyError.AVAILABILITY_TYPE]"
              >
                {{ errors[PropertyError.AVAILABILITY_TYPE] | translate }}
              </div>
              <div class="hour-buttons">
                <div
                  class="button"
                  *ngFor="let item of availabilityHoursArray"
                  (click)="
                    propertyForm.markAsDirty();
                    propertyForm.patchValue({
                      availabilityHours: item,
                    })
                  "
                  [ngClass]="{
                    disabled: !(
                      propertyForm.controls.availabilityType.value === 1
                    ),
                    selected:
                      propertyForm.controls.availabilityHours.value === item,
                  }"
                >
                  {{ "AVAILABILITY_HOURS_" + item | translate }}
                </div>
              </div>
              <div
                class="error"
                *ngIf="errors && errors[PropertyError.AVAILABILITY_HOURS]"
              >
                {{ errors[PropertyError.AVAILABILITY_HOURS] | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="dialog transparent"></div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">
            {{ "ADD_PROPERTY_MORE_SERVICES" | translate }}
          </div>
          <div class="main-content services">
            <div class="attribute full">
              <div class="label">
                {{ "ADD_PROPERTY_MORE_SERVICES_DESCRIPTION" | translate }}
              </div>
            </div>
            <div
              class="attribute radio-box extra-services"
              *ngFor="let item of extraServices"
              (click)="propertyForm.markAsDirty(); pushService(item)"
            >
              <div
                class="radio-box"
                [ngClass]="{
                  checked:
                    propertyForm.controls.extraServices.value.indexOf(item) !==
                    -1,
                }"
              >
                <div class="icon">
                  <svg-icon-sprite
                    [src]="'assets/sprites/sprite.svg#check'"
                    [width]="'12px'"
                    [height]="'20px'"
                  ></svg-icon-sprite>
                </div>
              </div>
              <div class="label">
                {{ "EXTRA_SERVICE_" + item.toString() | translate }}
              </div>
            </div>
          </div>
        </div>
        <div class="dialog transparent"></div>
      </div>
      <div class="section">
        <div class="panel">
          <div class="title">
            {{ "ADD_PROPERTY_PRICE_PACKAGES" | translate }}
          </div>
          <div class="main-content address">
            <div class="label">
              {{ "ADD_PROPERTY_PRICE_PACKAGES_DESCRIPTION" | translate }}
            </div>
            <div class="radio-button-group price-packages">
              <div
                *ngFor="let pricePackage of pricePackages; let last = last"
                (click)="
                  propertyForm.markAsDirty();
                  propertyForm.patchValue({
                    pricePackageId: pricePackage.id,
                  })
                "
              >
                <div class="radio-button price">
                  <div
                    class="icon"
                    [ngClass]="{
                      selected:
                        propertyForm.controls.pricePackageId.value ===
                        pricePackage.id,
                    }"
                  >
                    <div class="dot"></div>
                  </div>
                  <div class="text">
                    <div class="price-package-category">
                      <div
                        class="price-package-category-value"
                        *ngIf="
                          pricePackage.packageCategory === PackageCategory.Basic
                        "
                      >
                        {{ "ADD_PROPERTY_PRICE_PACKAGE_BASIC" | translate }}
                      </div>
                      <div
                        class="price-package-category-value"
                        *ngIf="
                          pricePackage.packageCategory === PackageCategory.Main
                        "
                      >
                        {{ "ADD_PROPERTY_PRICE_PACKAGE_MAIN" | translate }}
                      </div>
                      <div
                        class="price-package-category-value"
                        *ngIf="
                          pricePackage.packageCategory === PackageCategory.Boost
                        "
                      >
                        {{ "ADD_PROPERTY_PRICE_PACKAGE_BOOST" | translate }}
                      </div>
                      <div
                        class="price-package-limited"
                        *ngIf="pricePackage.limited"
                      >
                        <div class="price-package-limited-icon">
                          <svg-icon-sprite
                            [src]="'assets/sprites/sprite.svg#star'"
                            [width]="'14px'"
                            [height]="'14px'"
                          ></svg-icon-sprite>
                        </div>
                        <div class="price-package-limited-text">
                          {{ "ADD_PROPERTY_PRICE_PACKAGE_LIMITED" | translate }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="price-package-content" [ngClass]="{ last: last }">
                  <div class="price-package-price">
                    <ng-container
                      *ngIf="
                        pricePackage.price == null ||
                        (pricePackage.price != null && pricePackage.limited)
                      "
                    >
                      {{ "ADD_PROPERTY_PRICE_PACKAGE_FREE" | translate }}
                    </ng-container>
                    <div
                      *ngIf="pricePackage.price != null"
                      class="price-container"
                      [ngClass]="{ limited: pricePackage.limited }"
                    >
                      <span class="actual-price"
                        >{{ pricePackage.price }}€ /&nbsp;
                      </span>
                      <span
                        *ngIf="pricePackage.payInterval === PayInterval.Weekly"
                        class="pay-interval"
                      >
                        {{ "ADD_PROPERTY_PRICE_PACKAGE_WEEKLY" | translate }}
                      </span>
                      <span
                        *ngIf="pricePackage.payInterval === PayInterval.Monthly"
                        class="pay-interval"
                      >
                        {{ "ADD_PROPERTY_PRICE_PACKAGE_MONTHLY" | translate }}
                      </span>
                    </div>
                  </div>
                  <div
                    class="price-package-description"
                    [innerHTML]="pricePackage.description | translate"
                  ></div>
                  <div class="price-package-platforms">
                    <div class="price-package-platforms-greek">
                      <div class="price-package-platforms-title">
                        {{ "GREECE" | translate }}
                      </div>
                      <div
                        *ngFor="
                          let platform of filterPlatforms(
                            pricePackage.platforms
                          )
                        "
                        class="price-package-platforms-name"
                      >
                        <img
                          *ngIf="pricePackage.id === 3"
                          [src]="'assets/icons/star-golden.svg'"
                        />
                        {{ platform.website | url }}
                      </div>
                    </div>
                    <div class="price-package-platforms-non-greek">
                      <div class="price-package-platforms-title">
                        {{ "FOREIGN" | translate }}
                      </div>
                      <div
                        *ngFor="
                          let platform of filterPlatforms(
                            pricePackage.platforms,
                            false
                          )
                        "
                        class="price-package-platforms-name"
                      >
                        <img
                          *ngIf="pricePackage.id === 3"
                          [src]="'assets/icons/star-golden.svg'"
                        />
                        {{ platform.website | url }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog transparent"></div>
      </div>
      <div class="section last">
        <div class="panel last">
          <div class="main-content">
            <div
              class="last-call-publish"
              *ngIf="
                !editMode ||
                propertyForm.controls.publishState.value ===
                  PublishState.Unpublished ||
                (isAdmin &&
                  propertyForm.controls.publishState.value ===
                    PublishState.Pending)
              "
            >
              <ng-container *ngTemplateOutlet="submitButton"></ng-container>
            </div>
          </div>
        </div>
        <div class="dialog transparent"></div>
      </div>
    </form>
  </div>
</div>
<app-footer></app-footer>
<ng-template #submitButton>
  <button
    class="publish"
    (click)="onSubmit(true); publishCta()"
    [ngClass]="{
      'custom-tooltip': !propertyForm.invalid && !isAdmin,
    }"
    [disabled]="propertyForm.invalid || saving"
  >
    <span class="tooltiptext right" *ngIf="!propertyForm.invalid && !isAdmin">
      {{ "ADD_PROPERTY_PUBLISH_TOOLTIP" | translate }}
    </span>
    {{ "PUBLISH_STATE_ACTION_2" | translate }}
  </button>
</ng-template>

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { IconSpriteModule } from 'ng-svg-icon-sprite';
import { FooterComponent } from './components/footer/footer.component';
import { NavigationBarComponent } from './components/navigation-bar/navigation-bar.component';
import { SlidingMenuComponent } from './components/sliding-menu/sliding-menu.component';
import { NavigationBarLoggedInComponent } from './components/navigation-bar-logged-in/navigation-bar-logged-in.component';
import { SinglePropertyComponent } from './components/single-property/single-property.component';
import { FirstTimeModalComponent } from './components/first-time-modal/first-time-modal.component';
import { ActionModalComponent } from '../shared/components/action-modal/action-modal.component';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { RouterModule } from '@angular/router';

@NgModule({
  declarations: [
    FooterComponent,
    NavigationBarComponent,
    SlidingMenuComponent,
    NavigationBarLoggedInComponent,
    SinglePropertyComponent,
    FirstTimeModalComponent,
    ActionModalComponent,
  ],
  imports: [
    CommonModule,
    IconSpriteModule,
    TranslateModule.forChild(),
    NgbDropdownModule,
    RouterModule,
  ],
  exports: [
    FooterComponent,
    NavigationBarComponent,
    SlidingMenuComponent,
    NavigationBarLoggedInComponent,
    SinglePropertyComponent,
    FirstTimeModalComponent,
    ActionModalComponent,
  ],
})
export class SharedModule {}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-sign-up-modal',
  templateUrl: './sign-up-modal.component.html',
  styleUrls: ['./sign-up-modal.component.scss'],
})
export class SignUpModalComponent {
  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) {}

  /**
   * Navigates user to contact page.
   */
  public dismissModal(): void {
    this.modalService.dismissAll();
  }
}

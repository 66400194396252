import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AccountService } from '../services/account.service';

@Injectable({ providedIn: 'root' })
export class SignGuard {
  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {}

  /**
   * Check if user is authorized
   * @param {ActivatedRouteSnapshot} route The route
   * @param {RouterStateSnapshot} state The state
   * @returns {boolean} True if authorized, false if not authorized
   */
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.accountService.accountValue;
    if (account != null) {
      this.router.navigate(['/my-properties'], {
        queryParams: { returnUrl: state.url },
      });
      return false;
    }

    return true;
  }
}

<div class="close-button" (click)="dismissModal()">
  <div class="text">{{ "MODAL_CLOSE" | translate }}</div>
  <div class="icon">
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#close'"
      [width]="'24px'"
      [height]="'24px'"
    ></svg-icon-sprite>
  </div>
</div>
<div class="generic-modal">
  <div class="icon hero">
    <img src="../../../../assets/icons/breello-about.svg" alt="" />
  </div>
  <div class="title">{{ title | translate }}</div>
  <div class="main-text" [innerHTML]="text | translate"></div>
</div>

import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-action-modal',
  templateUrl: './action-modal.component.html',
  styleUrls: ['./action-modal.component.scss'],
})
export class ActionModalComponent {
  // Public/Private properties.
  @Input() public title: string = '';
  @Input() public text: string = '';
  @Input() public icon: string = '';
  @Input() public type: string = '';
  @Input() public primaryButtonText: string = '';
  @Input() public secondaryButtonText?: string;
  @Input() public closeButton = false;

  constructor(public modal: NgbActiveModal) {}

  /**
   * Dismisses the modal.
   */
  public dismissModal(): void {
    this.modal.dismiss('cancel');
  }

  /**
   * Applies the action.
   */
  public applyAction(): void {
    this.modal.close('apply');
  }
}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { IProperty } from 'src/app/interfaces/property.interface';
import { AccountService } from 'src/app/services/account.service';
import { PropertyService } from 'src/app/services/property.service';
import { FirstTimeModalComponent } from 'src/app/shared/components/first-time-modal/first-time-modal.component';

@Component({
  selector: 'app-my-properties',
  templateUrl: './my-properties.component.html',
  styleUrls: ['./my-properties.component.scss'],
})
export class MyPropertiesComponent implements OnInit {
  // Public properties
  public properties?: IProperty[];

  constructor(
    private propertyService: PropertyService,
    private modalService: NgbModal,
    private accountService: AccountService,
    private router: Router,
  ) {}

  /**
   *
   */
  ngOnInit(): void {
    window.scroll(0, 0);

    this.propertyService
      .getAll()
      .pipe(first())
      .subscribe((data) => {
        this.properties = data;
      });

    if (this.accountService.accountValue?.isFirstTime) {
      this.modalService.open(FirstTimeModalComponent, {
        centered: false,
        size: 'xl',
        backdrop: true,
      });

      this.accountService.accountValue.isFirstTime = false;
    }
  }
  /**
   * Navigates user to contact page.
   */
  public navigateToAddProperty(): void {
    this.router.navigate(['/add-property']);
  }
}

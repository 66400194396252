import { Component, OnInit, Input, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss'],
})
export class NavigationBarComponent implements OnInit {
  // Inputs/Outputs
  @Input() public isLight = false;

  // Public properites.
  public scrollingPosition: number;
  public showSlidingMenu: boolean = false;
  public frontOfficeURL: string;

  constructor(private router: Router) {
    this.scrollingPosition = 0;
    this.frontOfficeURL = environment.frontOfficeURL;
  }
  // DOM manipulation.
  /**
   *
   */
  @HostListener('window:scroll', ['$event'])
  public updateScrollPosition(): void {
    this.scrollingPosition = window.scrollY;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.showSlidingMenu = false;
  }

  /**
   * closeMenu
   */
  public closeMenu(): void {
    this.showSlidingMenu = !this.showSlidingMenu;
  }

  /**
   * opens sliding menu.
   *
   */
  public openMenu(): void {
    this.showSlidingMenu = !this.showSlidingMenu;
  }
}

import { Component, Output, EventEmitter, Input } from '@angular/core';
import { Router } from '@angular/router';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sliding-menu',
  templateUrl: './sliding-menu.component.html',
  styleUrls: ['./sliding-menu.component.scss'],
})
export class SlidingMenuComponent {
  // Inputs/Ouputs.
  @Output() public menuClosed = new EventEmitter<void>();
  @Input() public loggedIn?: boolean;
  public frontOfficeURL: string;

  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {
    this.frontOfficeURL = environment.frontOfficeURL;
  }

  /**
   * Navigates user to sign in page.
   */
  public navigateToSignIn(): void {
    this.menuClosed.emit();
    this.router.navigate(['/sign-in']);
  }

  /**
   * Navigates user to my properties page.
   */
  public navigateToMyProperties(): void {
    this.menuClosed.emit();
    this.router.navigate(['/my-properties']);
  }

  /**
   * Navigates user to add property page.
   */
  public navigateToAddProperty(): void {
    this.menuClosed.emit();
    this.router.navigate(['/add-property']);
  }

  /***
   * Emits event that closes the menu.
   */
  /**
   *
   */
  public closeMenu(): void {
    this.menuClosed.emit();
  }

  /**
   * Logs the user out.
   */
  public logout(): void {
    this.menuClosed.emit();
    this.accountService.logout();
  }
}

import { Injectable } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { AccountService } from '../services/account.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard {
  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {}

  /**
   * Check if user is authorized
   * @param {ActivatedRouteSnapshot} route The route
   * @param {RouterStateSnapshot} state The state
   * @returns {boolean} True if authorized, false if not authorized
   */
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const account = this.accountService.accountValue;
    if (account) {
      // Checking if route is restricted by role
      if (route.data.roles && !route.data.roles.includes(account.role)) {
        // Role not authorized so redirect to home page
        this.router.navigate(['/']);
        return false;
      }

      return true;
    }

    this.router.navigate(['/account/sign-in'], {
      queryParams: { returnUrl: state.url },
    });
    return false;
  }
}

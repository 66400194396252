import { AccountService } from '../services/account.service';

/**
 * The initializer of the app
 * @param {AccountService} accountService The account service
 * @returns {Promise} The promise
 */
export function AppInitializer(accountService: AccountService) {
  return () =>
    new Promise((resolve) => {
      // Attempting to refresh token on app start up to auto authenticate
      accountService.refreshToken().subscribe().add(resolve);
    });
}

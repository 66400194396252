<app-navigation-bar [isLight]="true"></app-navigation-bar>
<div class="sign-up-body">
  <div class="main-content">
    <div class="two-col-content">
      <div class="second-col">
        <div class="title">
          <div class="text">{{ "SIGN_UP_OPTIONS_TITLE" | translate }}</div>
        </div>
        <div class="main-text">
          {{ "SIGN_UP_OPTIONS_TEXT" | translate }}
        </div>
        <div class="sign-up-buttons">
          <asl-google-signin-button
            type="standard"
            size="large"
            [width]="322"
          ></asl-google-signin-button>
          <div class="seperator">{{ "SIGN_UP_OPTIONS_OR" | translate }}</div>
          <div class="sso-button" (click)="signInWithFacebook()">
            <div class="button-image">
              <img
                class="facebook-icon"
                src="assets/icons/facebook.svg"
                alt=""
              />
            </div>
            <div class="text">{{ "SIGN_UP_OPTIONS_FACEBOOK" | translate }}</div>
          </div>

          <div class="seperator">{{ "SIGN_UP_OPTIONS_OR" | translate }}</div>
          <div class="email-sign-up" (click)="navigateToSignUp()">
            {{ "SIGN_UP_OPTIONS_EMAIL_PASSWORD" | translate }}
          </div>
        </div>
        <div class="sign-up">
          <div class="text">
            {{ "SIGN_UP_OPTIONS_HAVE_ACCOUNT" | translate }}
          </div>
          <div class="link" (click)="navigateToSignIn()">
            {{ "SIGN_UP_SIGN_IN" | translate }}
          </div>
        </div>
        <div class="help">
          <a href="{{ frontOfficeURL }}/contact">
            {{ "SIGN_UP_OPTIONS_HELP" | translate }}
          </a>
        </div>
      </div>
      <div class="first-col">
        <div class="image">
          <img src="../../../assets/icons/breello-sign-up.svg" alt="" />
        </div>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>

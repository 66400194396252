import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { environment } from 'src/environments/environment';
import { GenericModalComponent } from '../generic-modal/generic-modal.component';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {
  // Public/private properties.
  public forgotPasswordForm!: UntypedFormGroup;
  public token?: string;
  public error: string = '';
  public frontOfficeURL: string;

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private accountService: AccountService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
  ) {
    this.frontOfficeURL = environment.frontOfficeURL;
  }

  /**
   *
   */
  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params != null && params['token'] != null) {
        this.token = params['token'];
        this.forgotPasswordForm = this.fb.group(
          {
            password: ['', Validators.required],
            confirmPassword: ['', Validators.required],
            acceptTerms: [false, Validators.requiredTrue],
          },
          { validators: this.matchPasswords() },
        );
      } else {
        this.token = '';
        this.forgotPasswordForm = this.fb.group({
          email: [
            '',
            Validators.compose([Validators.email, Validators.required]),
          ],
        });
      }
    });
  }

  /**
   * Checks if the passwords do match.
   * @returns {ValidatorFn} The validation's result
   */
  private matchPasswords(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = control.value.password;
      const confirmPassword = control.value.confirmPassword;

      return password === confirmPassword ? null : { notSame: true };
    };
  }

  /**
   * Navigates user to sign in page.
   */
  public navigateToSignIn(): void {
    this.router.navigate(['/sign-in']);
    window.scroll(0, 0);
  }

  /**
   * Submits form if it's valid.
   */
  public onSubmit(): void {
    if (this.forgotPasswordForm.valid) {
      if (this.token === '') {
        this.accountService
          .forgotPassword(this.forgotPasswordForm.value.email)
          .pipe(first())
          .subscribe({
            next: () => {
              const modalRef = this.modalService.open(GenericModalComponent, {
                centered: false,
                size: 'xl',
                backdrop: true,
              });
              modalRef.componentInstance.title = 'FORGOT_PASSWORD_1_TITLE';
              modalRef.componentInstance.text = 'FORGOT_PASSWORD_1_TEXT';
              this.router.navigate(['/sign-in']);
            },
            error: (error) => {
              this.error = error.error.message;
            },
          });
      } else if (this.token != null && this.token !== '') {
        this.accountService
          .resetPassword(
            this.token,
            this.forgotPasswordForm.value.password,
            this.forgotPasswordForm.value.confirmPassword,
          )
          .pipe(first())
          .subscribe({
            next: () => {
              const modalRef = this.modalService.open(GenericModalComponent, {
                centered: false,
                size: 'xl',
                backdrop: true,
              });
              modalRef.componentInstance.title = 'FORGOT_PASSWORD_2_TITLE';
              modalRef.componentInstance.text = 'FORGOT_PASSWORD_2_TEXT';
              this.router.navigate(['/sign-in']);
            },
            error: (error) => {
              this.error = error.error.message;
            },
          });
      }
    }
  }
}

import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-first-time-model',
  templateUrl: './first-time-modal.component.html',
  styleUrls: ['./first-time-modal.component.scss'],
})
export class FirstTimeModalComponent {
  constructor(
    private modalService: NgbModal,
    private router: Router,
  ) {}

  /**
   * Dismisses the modal.
   */
  public dismissModal(): void {
    this.modalService.dismissAll();
  }

  /**
   * Navigates to the add property page.
   */
  public navigateToCreate(): void {
    this.modalService.dismissAll();
    this.router.navigate(['/add-property']);
  }
}

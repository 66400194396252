<div class="close-button" (click)="dismissModal()" *ngIf="closeButton">
  <div class="text">{{ "MODAL_CLOSE" | translate }}</div>
  <div class="icon">
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#close'"
      [width]="'20px'"
      [height]="'20px'"
    ></svg-icon-sprite>
  </div>
</div>
<div class="action-modal">
  <div
    class="icon"
    [ngClass]="{
      'high-risk': type === 'high-risk',
      warning: type === 'warning',
      default: type === 'default',
      success: type === 'success',
    }"
  >
    <svg-icon-sprite
      [src]="'assets/sprites/sprite.svg#' + icon"
      [width]="'48px'"
      [height]="'48px'"
    ></svg-icon-sprite>
  </div>
  <div
    class="title"
    [ngClass]="{
      'high-risk': type === 'high-risk',
      warning: type === 'warning',
      default: type === 'default',
      success: type === 'success',
    }"
  >
    {{ title | translate }}
  </div>
  <div class="text" [innerHtml]="text | translate"></div>
  <div class="buttons">
    <div
      *ngIf="secondaryButtonText != null"
      class="button outline"
      [ngClass]="{
        'high-risk': type === 'high-risk',
        warning: type === 'warning',
        default: type === 'default',
        success: type === 'success',
      }"
      (click)="dismissModal()"
    >
      {{ secondaryButtonText | translate }}
    </div>
    <div
      class="button fill"
      [ngClass]="{
        'high-risk': type === 'high-risk',
        warning: type === 'warning',
        default: type === 'default',
        success: type === 'success',
      }"
      (click)="applyAction()"
    >
      {{ primaryButtonText | translate }}
    </div>
  </div>
</div>

<app-navigation-bar [isLight]="true"></app-navigation-bar>
<div class="forgot-password">
  <div class="main-content">
    <div class="two-col-content">
      <div class="second-col">
        <div class="title">
          <div class="text" *ngIf="token === ''">
            {{ "FORGOT_PASSWORD_TITLE_1" | translate }}
          </div>
          <div class="text" *ngIf="token != null && token !== ''">
            {{ "FORGOT_PASSWORD_TITLE_2" | translate }}
          </div>
        </div>
        <div class="main-text" *ngIf="token === ''">
          {{ "FORGOT_PASSWORD_TEXT" | translate }}
        </div>
        <div class="form">
          <form
            *ngIf="token === ''"
            [formGroup]="forgotPasswordForm"
            (keydown.enter)="onSubmit()"
          >
            <div class="form-section">
              <label>
                <div class="form-section">
                  <div class="text">
                    {{ "INPUT_EMAIL" | translate }}
                  </div>
                  <div class="input">
                    <input
                      type="text"
                      formControlName="email"
                      placeholder=" {{ 'INPUT_EMAIL_PLACEHOLDER' | translate }}"
                      [ngClass]="{
                        'error-input':
                          forgotPasswordForm.get('email')?.invalid &&
                          (forgotPasswordForm.get('email')?.dirty ||
                            forgotPasswordForm.get('email')?.touched),
                      }"
                    />
                    <div
                      class="error-icon"
                      *ngIf="
                        forgotPasswordForm.get('email')?.invalid &&
                        (forgotPasswordForm.get('email')?.dirty ||
                          forgotPasswordForm.get('email')?.touched)
                      "
                    >
                      <svg-icon-sprite
                        [src]="'assets/sprites/sprite.svg#alert'"
                        [width]="'16px'"
                        [height]="'16px'"
                      ></svg-icon-sprite>
                    </div>
                  </div>
                  <div
                    class="error"
                    *ngIf="
                      forgotPasswordForm.get('email')?.invalid &&
                      (forgotPasswordForm.get('email')?.dirty ||
                        forgotPasswordForm.get('email')?.touched)
                    "
                  >
                    <ng-container
                      *ngIf="forgotPasswordForm.get('email')?.errors?.required"
                    >
                      {{ "INPUT_EMAIL_ERROR_REQUIRED" | translate }}
                    </ng-container>
                    <ng-container
                      *ngIf="forgotPasswordForm.get('email')?.errors?.email"
                    >
                      {{ "INPUT_EMAIL_ERROR_INVALID" | translate }}
                    </ng-container>
                  </div>
                </div>
              </label>
            </div>
          </form>
          <form
            *ngIf="token != null && token !== ''"
            [formGroup]="forgotPasswordForm"
            (keydown.enter)="onSubmit()"
          >
            <div class="form-section">
              <label>
                <div class="text password">
                  <div class="text-pass">
                    {{ "INPUT_PASSWORD_NEW" | translate }}
                  </div>
                </div>
                <div class="input">
                  <input
                    type="password"
                    formControlName="password"
                    placeholder=" {{
                      'INPUT_PASSWORD_NEW_PLACEHOLDER' | translate
                    }}"
                    [ngClass]="{
                      'error-input':
                        forgotPasswordForm.get('password')?.invalid &&
                        (forgotPasswordForm.get('password')?.dirty ||
                          forgotPasswordForm.get('password')?.touched),
                    }"
                  />
                </div>
                <div
                  class="error"
                  *ngIf="
                    forgotPasswordForm.get('password')?.invalid &&
                    (forgotPasswordForm.get('password')?.dirty ||
                      forgotPasswordForm.get('password')?.touched)
                  "
                >
                  <ng-container
                    *ngIf="forgotPasswordForm.get('password')?.errors?.required"
                  >
                    {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
                  </ng-container>
                </div>
              </label>
            </div>
            <div class="form-section">
              <label>
                <div class="text password">
                  <div class="text-pass">
                    {{ "INPUT_PASSWORD_NEW_RETYPE" | translate }}
                  </div>
                </div>
                <div class="input">
                  <input
                    type="password"
                    formControlName="confirmPassword"
                    placeholder=" {{
                      'INPUT_PASSWORD_NEW_RETYPE_PLACEHOLDER' | translate
                    }}"
                    [ngClass]="{
                      'error-input':
                        forgotPasswordForm.get('confirmPassword')?.invalid &&
                        (forgotPasswordForm.get('confirmPassword')?.dirty ||
                          forgotPasswordForm.get('confirmPassword')?.touched),
                    }"
                  />
                </div>
                <div
                  class="error"
                  *ngIf="
                    (forgotPasswordForm.get('confirmPassword')?.invalid ||
                      forgotPasswordForm.errors?.notSame) &&
                    (forgotPasswordForm.get('confirmPassword')?.dirty ||
                      forgotPasswordForm.get('confirmPassword')?.touched)
                  "
                >
                  <ng-container
                    *ngIf="
                      forgotPasswordForm.get('confirmPassword')?.errors
                        ?.required
                    "
                  >
                    {{ "INPUT_PASSWORD_ERROR_REQUIRED" | translate }}
                  </ng-container>
                  <ng-container
                    *ngIf="
                      forgotPasswordForm.errors?.notSame &&
                      !forgotPasswordForm.get('confirmPassword')?.errors
                        ?.required
                    "
                  >
                    {{ "INPUT_PASSWORD_ERROR_MATCH" | translate }}
                  </ng-container>
                </div>
                <div class="error">
                  {{ error | translate }}
                </div>
              </label>
            </div>
            <div class="form-section-checkbox">
              <div class="input">
                <input
                  type="checkbox"
                  formControlName="acceptTerms"
                  id="acceptTerms"
                  class="form-check-input"
                />
              </div>
              <div class="text">
                <label for="acceptTerms" class="form-check-label">
                  {{ "SIGN_UP_AGREE_TERMS" | translate }}
                </label>
              </div>
            </div>
          </form>
          <button
            type="submit"
            class="button"
            (click)="onSubmit()"
            [disabled]="!forgotPasswordForm.valid"
          >
            <div class="text">
              {{ "FORGOT_PASSWORD_RESET" | translate }}
            </div>
          </button>
        </div>
        <div class="help">
          <a href="{{ frontOfficeURL }}/contact">
            {{ "SIGN_IN_NEED_HELP" | translate }}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
<app-footer></app-footer>

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EMPTY, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AccountService } from '../services/account.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private router: Router,
    private accountService: AccountService,
  ) {
    //
  }

  /**
   * Auth Interceptor
   * @param {HttpRequest<any>} request The http request
   * @param {HttpHandler} next The handler
   * @returns {HttpErrorResponse} The http response
   */
  // eslint-disable-next-line @typescript-eslint/explicit-member-accessibility
  intercept(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    request: HttpRequest<any>,
    next: HttpHandler,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ): Observable<HttpEvent<any>> {
    // Get the security token
    const token = this.accountService.accountValue?.jwtToken;

    // Add the authorization header to the request (by cloning it) if the token exists
    const changedReq =
      token != null
        ? request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          })
        : request;

    return next.handle(changedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error && (error.status === 401 || error.status === 403)) {
          this.accountService.accountSubject.next(null);
          this.router.navigate(['/sign-in']);

          return EMPTY;
        }
        // If it's not an authentication error, this interceptor is not concerned
        return throwError(error);
      }),
    );
  }
}

export enum PropertyError {
  PRICE = 'Price',
  ROOMS = 'Rooms',
  SIZE = 'Size',
  CONTACT_PHONES = 'ContactPhones',
  CONTACT_EMAILS = 'ContactEmails',
  PROTOCOL_NUMBER = 'ProtocolNumber',
  SECURITY_NUMBER = 'SecurityNumber',
  ACTION = 'Action',
  TYPE = 'Type',
  SUBTYPE = 'SubType',
  FLOOR = 'Floor',
  FLOOR_TO = 'FloorTo',
  VIEW = 'View',
  PARKING = 'Parking',
  HEATING_TYPE = 'HeatingType',
  HEATING_CATEGORY = 'HeatingCategory',
  CONTACT_PREFERENCE = 'ContactPreference',
  AVAILABILITY_TYPE = 'AvailabilityType',
  AVAILABILITY_HOURS = 'AvailabilityHours',
}

import {
  ChangeDetectorRef,
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { PublishState } from 'src/app/enumerations/publish-state.enumeration';
import {
  UntypedFormControl,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Action } from '../../enumerations/action.enumeration';
import { AvailabilityHours } from '../../enumerations/availability-hours.enumeration';
import { AvailabilityType } from '../../enumerations/availability-type.enumeration';
import { ContactPreference } from '../../enumerations/contact-preference.enumeration';
import { EnergyClass } from '../../enumerations/energy-class.enumeration';
import { ExtraAttribute } from '../../enumerations/extra-attributes.enumeration';
import { Floor } from '../../enumerations/floor.enumeration';
import { HeatingCategory } from '../../enumerations/heating-category.enumeration';
import { HeatingType } from '../../enumerations/heating-type.enumeration';
import { Parking } from '../../enumerations/parking.enumeration';
import { SubType } from '../../enumerations/sub-type.enumeration';
import { Type } from '../../enumerations/type.enumeration';
import { View } from '../../enumerations/view.enumeration';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import {
  circle,
  icon,
  LatLng,
  latLng,
  Layer,
  LeafletMouseEvent,
  MapOptions,
  marker,
  tileLayer,
} from 'leaflet';
import { PropertyService } from 'src/app/services/property.service';
import { first, takeUntil } from 'rxjs/operators';
import { PropertyError } from 'src/app/enumerations/property-error.enumeration';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { IPhoto, IProperty } from 'src/app/interfaces/property.interface';
import { environment } from 'src/environments/environment';
import { AccountService } from 'src/app/services/account.service';
import { Role } from 'src/app/enumerations/role.enumeration';
import { ExtraService } from 'src/app/enumerations/extra-service.enumeration';
import { ActionModalComponent } from 'src/app/shared/components/action-modal/action-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PricePackageService } from 'src/app/services/price-package.service';
import {
  IPlatform,
  IPricePackage,
} from 'src/app/interfaces/price-package.interface';
import { PackageCategory } from 'src/app/enumerations/package-category.enumeration';
import { PayInterval } from 'src/app/enumerations/pay-interval.enumeration';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { filterPackagesById } from 'src/app/shared/functions/filterPackagesById';
import { MessageService } from 'primeng/api';
import { TranslateService } from '@ngx-translate/core';
import { FileSelectEvent } from 'primeng/fileupload';
import { ChipsAddEvent } from 'primeng/chips';
import { Subject } from 'rxjs';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare let google: any;

@Component({
  selector: 'app-property',
  templateUrl: './property.component.html',
  styleUrls: ['./property.component.scss'],
  providers: [MessageService],
})
export class PropertyComponent implements OnInit, OnDestroy, AfterViewInit {
  public actions: string[] = [];
  public types: number[] = [];
  public subTypes: string[] = [];
  public floors: string[] = [];
  public filteredsubTypes: string[] = [];
  public extraAttributes: number[] = [];
  public contactPreferences: number[] = [];
  public extraServices: number[] = [];
  public views: string[] = [];
  public parkings: string[] = [];
  public heatingTypes: string[] = [];
  public heatingCategories: string[] = [];
  public energyClasses: string[] = [];
  public availabilityTypes: number[] = [];
  public availabilityHoursArray: number[] = [];
  public PublishState = PublishState;
  public propertyForm = this.fb.group({
    action: [null, Validators.required],
    type: [null, Validators.required],
    subType: [null],
    price: [null, Validators.compose([Validators.required, Validators.min(1)])],
    rooms: [0, Validators.compose([Validators.required, Validators.min(1)])],
    floor: [null],
    floorTo: [null],
    size: [null, Validators.compose([Validators.required, Validators.min(1)])],
    address: ['', Validators.required],
    protocolNumber: [''],
    securityNumber: [''],
    latitude: [null],
    longitude: [null],
    descriptionGr: [],
    descriptionEn: [],
    contactPhones: [[], Validators.required],
    contactEmails: [[], Validators.required],
    constructionYear: [
      null,
      Validators.compose([
        Validators.required,
        Validators.min(1900),
        Validators.max(new Date().getFullYear()),
      ]),
    ],
    view: [null],
    parking: [null],
    heatingType: [null],
    heatingCategory: [null],
    energyClass: [null],
    exactLocation: [true, Validators.required],
    publishState: [0, Validators.required],
    availabilityType: [0],
    availabilityHours: [null],
    extraAttributes: [[]],
    contactPreferences: [[]],
    extraServices: [[]],
    pricePackageId: [],
    tags: new UntypedFormControl([]),
    existingPhotoIds: [[]],
    contactName: [],
  });
  public photos: File[] = [];
  public existingPhotos: IPhoto[] = [];
  public mapOptions: MapOptions;
  public layers: Layer[] = [];
  public center!: LatLng;
  public zoom: number = 13;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public autocomplete: any;
  public editMode: boolean = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public errors: any;
  public PropertyError = PropertyError;
  public photosPath: string = '';
  public api: string;
  public scrollingPosition: number = 0;
  public saving: boolean = false;
  public isAdmin: boolean = false;
  public pricePackages: IPricePackage[] = [];
  public PackageCategory = PackageCategory;
  public PayInterval = PayInterval;
  private propertyId: number = -1;
  public platforms: IPlatform[] = [];
  public propertyPricePackage?: IPricePackage;
  private navigationSubscription$ = new Subject<void>();
  private saved = false;
  public currentLat: number = 0;
  public currentLng: number = 0;
  constructor(
    private fb: UntypedFormBuilder,
    private domSanitizer: DomSanitizer,
    private cdRef: ChangeDetectorRef,
    private propertyService: PropertyService,
    private pricePackageService: PricePackageService,
    public accountService: AccountService,
    private router: Router,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private messageService: MessageService,
    private translateService: TranslateService,
    private $gaService: GoogleAnalyticsService,
  ) {
    this.mapOptions = {
      layers: [
        tileLayer('http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
          maxZoom: 18,
          attribution: '...',
        }),
      ],
      zoom: 5,
      center: latLng(40.5875504, 22.9426811),
    };
    this.api = environment.endpoint;
    this.isAdmin = this.accountService.accountValue?.role === Role.Admin;

    const currentUserEmail = this.accountService.accountValue?.email;
    this.propertyForm.controls['contactEmails'].value.push(currentUserEmail);
    this.router.events
      .pipe(takeUntil(this.navigationSubscription$))
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          if (event.navigationTrigger && !this.saved) {
            if (!this.propertyForm.pristine) {
              const modalRef = this.modalService.open(ActionModalComponent, {
                centered: true,
                size: 'lg',
                backdrop: true,
                windowClass: 'action-dialog-modal',
              });
              modalRef.componentInstance.title =
                'MODAL_EXITING_WITHOUT_SAVE_TITLE';
              modalRef.componentInstance.text =
                'MODAL_EXITING_WITHOUT_SAVE_BODY';
              modalRef.componentInstance.icon = 'warning_fill';
              modalRef.componentInstance.type = 'warning';
              modalRef.componentInstance.primaryButtonText =
                'ADD_PROPERTY_SAVE';
              modalRef.componentInstance.closeButton = true;
              if (this.editMode) {
                modalRef.result.then(
                  () => {
                    this.saving = true;
                    this.propertyService
                      .edit(
                        this.propertyId,
                        this.propertyForm.value,
                        this.photos,
                        false,
                      )
                      .pipe(first())
                      .subscribe({
                        next: () => {
                          this.saved = true;
                          location.reload();
                          if (
                            this.router.url.includes('/my-properties') ||
                            this.router.url.includes('/edit-property')
                          ) {
                            location.reload();
                          }
                        },
                        error: (error) => {
                          this.errors = {};
                          for (const singleError of error.error.errors) {
                            this.errors[singleError.field] =
                              singleError.errorString;
                          }
                        },
                      });
                  },
                  () => {},
                );
              } else {
                modalRef.result.then(
                  () => {
                    this.saving = true;
                    this.propertyService
                      .create(this.propertyForm.value, this.photos, false)
                      .pipe(first())
                      .subscribe({
                        next: () => {
                          this.saved = true;
                          if (
                            this.router.url.includes('/my-properties') ||
                            this.router.url.includes('/edit-property')
                          ) {
                            location.reload();
                          }
                        },
                        error: (error) => {
                          if (
                            error.error != null &&
                            error.error.errors == null
                          ) {
                            this.messageService.add({
                              severity: 'error',
                              summary: this.translateService.instant('ERROR'),
                              detail: this.translateService.instant(
                                'MY_PROPERTIES_SAVE_ERROR_OCCURED',
                              ),
                            });
                            this.saving = false;
                          } else {
                            this.errors = {};
                            for (const singleError of error.error.errors) {
                              this.errors[singleError.field] =
                                singleError.errorString;
                            }
                          }
                        },
                      });
                  },
                  () => {},
                );
              }
            }
          }
        }
      });
  }

  /**
   *
   */
  ngOnInit(): void {
    window.scroll(0, 0);

    this.prepareFormFields();

    this.route.params.subscribe((params) => {
      if (params.id != null) {
        this.editMode = true;
        this.propertyId = parseInt(params.id);
        this.propertyService
          .getProperty(this.propertyId)
          .pipe(first())
          .subscribe({
            next: (property: IProperty) => {
              this.photosPath = property.photosPath;
              this.populateForm(property);
            },
            error: () => {},
          });
      }
      // We replace history to be able to navigate to my-properties when user clicks on browser back button.
      history.replaceState({ page: 2 }, 'title 1', 'my-properties');
      this.editMode
        ? history.pushState(
            { page: 1 },
            'title 1',
            'edit-property/' + params.id,
          )
        : history.pushState({ page: 1 }, 'title 1', 'add-property');
    });

    this.pricePackageService
      .getAll()
      .pipe(first())
      .subscribe((pricePackages: IPricePackage[]) => {
        this.pricePackages = pricePackages.filter((item) => {
          return item.id !== 1;
        });
        if (this.propertyForm.controls.pricePackageId.value == null) {
          this.propertyForm.patchValue({
            pricePackageId: this.pricePackages[0].id,
          });
        } else {
          const packageId = this.propertyForm.controls.pricePackageId.value;
          this.propertyPricePackage = filterPackagesById(
            this.pricePackages,
            packageId,
          );
          this.platforms = this.propertyPricePackage?.platforms ?? [];
        }
      });

    this.propertyForm.markAsPristine();
  }

  /**
   *
   */
  ngAfterViewInit(): void {
    const input = document.getElementById(
      'property-address',
    ) as HTMLInputElement;
    const options = {
      fields: ['address_components', 'geometry', 'icon', 'name'],
      strictBounds: false,
      types: ['address'],
    };

    this.autocomplete = new google.maps.places.Autocomplete(input, options);

    this.autocomplete.addListener('place_changed', this.updatePlace.bind(this));
  }

  /**
   *
   */
  ngOnDestroy(): void {
    this.navigationSubscription$.next();
    this.navigationSubscription$.complete();
  }

  // DOM manipulation.
  /**
   *
   */
  @HostListener('window:scroll', ['$event'])
  public updateScrollPosition(): void {
    this.scrollingPosition = window.scrollY;
  }

  /**
   * Prepares displayed form fields.
   */
  private prepareFormFields() {
    this.actions = Object.keys(Action).filter(
      (key) => typeof Action[key as keyof typeof Action] === 'string',
    );
    this.types = Object.keys(Type)
      .filter((key) => typeof Type[key as keyof typeof Type] === 'string')
      .map((key) => parseInt(key));
    this.subTypes = Object.keys(SubType).filter(
      (key) => typeof SubType[key as keyof typeof SubType] === 'string',
    );
    this.floors = Object.keys(Floor).filter(
      (key) => typeof Floor[key as keyof typeof Floor] === 'string',
    );
    this.extraAttributes = Object.keys(ExtraAttribute)
      .filter(
        (key) =>
          typeof ExtraAttribute[key as keyof typeof ExtraAttribute] ===
          'string',
      )
      .map((key) => parseInt(key));
    this.contactPreferences = Object.keys(ContactPreference)
      .filter(
        (key) =>
          typeof ContactPreference[key as keyof typeof ContactPreference] ===
          'string',
      )
      .map((key) => parseInt(key));
    this.extraServices = Object.keys(ExtraService)
      .filter(
        (key) =>
          typeof ExtraService[key as keyof typeof ExtraService] === 'string',
      )
      .map((key) => parseInt(key));
    this.views = Object.keys(View).filter(
      (key) => typeof View[key as keyof typeof View] === 'string',
    );
    this.parkings = Object.keys(Parking).filter(
      (key) => typeof Parking[key as keyof typeof Parking] === 'string',
    );
    this.heatingTypes = Object.keys(HeatingType).filter(
      (key) => typeof HeatingType[key as keyof typeof HeatingType] === 'string',
    );
    this.heatingCategories = Object.keys(HeatingCategory).filter(
      (key) =>
        typeof HeatingCategory[key as keyof typeof HeatingCategory] ===
        'string',
    );
    this.energyClasses = Object.keys(EnergyClass).filter(
      (key) => typeof EnergyClass[key as keyof typeof EnergyClass] === 'string',
    );
    this.availabilityTypes = Object.keys(AvailabilityType)
      .filter(
        (key) =>
          typeof AvailabilityType[key as keyof typeof AvailabilityType] ===
          'string',
      )
      .map((key) => parseInt(key));
    this.availabilityHoursArray = Object.keys(AvailabilityHours)
      .filter(
        (key) =>
          typeof AvailabilityHours[key as keyof typeof AvailabilityHours] ===
          'string',
      )
      .map((key) => parseInt(key));
  }

  /**
   * Populates the form based on the given property.
   * @param {IProperty} property The property to populate the form.
   */
  private populateForm(property: IProperty): void {
    this.propertyForm.patchValue({
      action: property.action,
      address: property.address,
      availabilityHours: property.availabilityHours,
      availabilityType: property.availabilityType,
      constructionYear: property.constructionYear,
      contactEmails: property.contactEmails,
      contactPhones: property.contactPhones,
      descriptionEn: property.descriptionEn,
      descriptionGr: property.descriptionGr,
      energyClass: property.energyClass,
      exactLocation: property.exactLocation,
      extraAttributes: property.extraAttributes,
      contactPreferences: property.contactPreferences,
      extraServices: property.extraServices,
      floor: property.floor,
      floorTo: property.floorTo,
      heatingCategory: property.heatingCategory,
      heatingType: property.heatingType,
      latitude: property.latitude,
      longitude: property.longitude,
      parking: property.parking,
      price: property.price,
      protocolNumber: property.protocolNumber,
      rooms: property.rooms,
      securityNumber: property.securityNumber,
      size: property.size,
      subType: property.subType,
      tags: property.tags,
      type: property.type,
      view: property.view,
      publishState: property.publishState,
      pricePackageId: property.pricePackage.id,
      contactName: property.contactName,
    });

    // Updating the map
    if (property.latitude != null && property.longitude != null) {
      this.currentLat = property.latitude;
      this.currentLng = property.longitude;
      this.updateLayer(property.latitude, property.longitude);
    }

    // Updating the subtypes
    if (property.type != null) {
      this.filterSubtypes(property.type);
      this.propertyForm.patchValue({
        subType: property.subType,
      });
    }

    // Adding existing photos
    if (property.photos != null && property.photos.length > 0) {
      this.existingPhotos = property.photos;
      this.propertyForm.patchValue({
        existingPhotoIds: property.photos.map((photo) => photo.id),
      });
    }
  }

  /**
   * Filters subTypes according to type.
   * @param {number} type The type
   */
  public filterSubtypes(type: number) {
    if (type == 3) {
      this.filteredsubTypes = this.subTypes.slice(17, 21);
      this.filteredsubTypes.push(this.subTypes[22]);
    } else if (type >= 2) {
      this.filteredsubTypes = this.subTypes.slice(12, 16);
    } else if (type >= 1) {
      this.filteredsubTypes = this.subTypes.slice(6, 12);
      this.filteredsubTypes.push(this.subTypes[16]);
      this.filteredsubTypes.push(this.subTypes[21]);
    } else {
      this.filteredsubTypes = this.subTypes.slice(0, 6);
    }
    if (this.propertyForm.controls.subType != null) {
      this.propertyForm.patchValue({ subType: null });
    }
  }

  /**
   * Converts type if needed and
   * submits form if it's valid.
   * @param {boolean} publish Indicates if the property should be published
   */
  public onSubmit(publish: boolean): void {
    if (publish) {
      this.makeFormDirty();
      if (this.propertyForm.valid) {
        if (this.isAdmin) {
          if (this.editMode) {
            this.propertyService
              .edit(this.propertyId, this.propertyForm.value, this.photos, true)
              .pipe(first())
              .subscribe({
                next: () => {
                  this.saved = true;
                  this.router.navigate(['/my-properties']);
                },
                error: (error) => {
                  this.errors = {};
                  for (const singleError of error.error.errors) {
                    this.errors[singleError.field] = singleError.errorString;
                  }
                },
              });
          } else {
            this.propertyService
              .create(this.propertyForm.value, this.photos, true)
              .pipe(first())
              .subscribe({
                next: () => {
                  this.saved = true;
                  this.router.navigate(['/my-properties']);
                },
                error: (error) => {
                  this.errors = {};
                  for (const singleError of error.error.errors) {
                    this.errors[singleError.field] = singleError.errorString;
                  }
                },
              });
          }
        } else {
          const modalRef = this.modalService.open(ActionModalComponent, {
            centered: false,
            size: 'xl',
            backdrop: true,
            windowClass: 'action-dialog-modal',
          });
          modalRef.componentInstance.title = 'MODAL_PUBLISH_PROPERTY_TITLE';
          modalRef.componentInstance.text = 'MODAL_PUBLISH_PROPERTY_TEXT';
          modalRef.componentInstance.icon = 'warning_fill';
          modalRef.componentInstance.type = 'default';
          modalRef.componentInstance.primaryButtonText =
            'MODAL_PUBLISH_PROPERTY_ACTION';
          modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';

          if (this.editMode) {
            modalRef.componentInstance.primaryButtonText =
              'MODAL_UPDATE_PROPERTY_ACTION';
            modalRef.componentInstance.primaryButtonText =
              'MODAL_UPDATE_PROPERTY_ACTION';
            modalRef.componentInstance.title = 'MODAL_UPDATE_PROPERTY_TITLE';
          }

          if (this.editMode) {
            modalRef.result.then(
              () => {
                this.propertyService
                  .edit(
                    this.propertyId,
                    this.propertyForm.value,
                    this.photos,
                    true,
                  )
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      if (!this.isAdmin) {
                        this.saved = true;
                        this.openPropertySuccessfulRegistrationModal();
                      } else {
                        this.router.navigate(['/my-properties']);
                      }
                    },
                    error: (error) => {
                      this.errors = {};
                      for (const singleError of error.error.errors) {
                        this.errors[singleError.field] =
                          singleError.errorString;
                      }
                    },
                  });
              },
              () => {},
            );
          } else {
            modalRef.result.then(
              () => {
                this.propertyService
                  .create(this.propertyForm.value, this.photos, true)
                  .pipe(first())
                  .subscribe({
                    next: () => {
                      if (!this.isAdmin) {
                        this.saved = true;
                        this.openPropertySuccessfulRegistrationModal();
                      } else {
                        this.router.navigate(['/my-properties']);
                      }
                    },
                    error: (error) => {
                      this.errors = {};
                      for (const singleError of error.error.errors) {
                        this.errors[singleError.field] =
                          singleError.errorString;
                      }
                    },
                  });
              },
              () => {},
            );
          }
        }
      }
    } else {
      this.saving = true;
      if (this.editMode) {
        this.saving = true;
        this.propertyService
          .edit(this.propertyId, this.propertyForm.value, this.photos, false)
          .pipe(first())
          .subscribe({
            next: () => {
              this.saved = true;
              location.reload();
            },
            error: (error) => {
              this.errors = {};
              for (const singleError of error.error.errors) {
                this.errors[singleError.field] = singleError.errorString;
              }
            },
          });
      } else {
        this.saving = true;
        this.propertyService
          .create(this.propertyForm.value, this.photos, false)
          .pipe(first())
          .subscribe({
            next: () => {
              this.saved = true;
              this.router.navigate(['/my-properties']);
            },
            error: (error) => {
              if (error.error != null && error.error.errors == null) {
                this.messageService.add({
                  severity: 'error',
                  summary: this.translateService.instant('ERROR'),
                  detail: this.translateService.instant(
                    'MY_PROPERTIES_SAVE_ERROR_OCCURED',
                  ),
                });
                this.saving = false;
              } else {
                this.errors = {};
                for (const singleError of error.error.errors) {
                  this.errors[singleError.field] = singleError.errorString;
                }
              }
            },
          });
      }
    }
  }

  /**
   * Opens the property successful registraion modal
   */
  private openPropertySuccessfulRegistrationModal(): void {
    const modalRef = this.modalService.open(ActionModalComponent, {
      centered: false,
      size: 'xl',
      backdrop: true,
      windowClass: 'action-dialog-modal',
    });

    modalRef.componentInstance.title = 'MODAL_REGISTERED_PROPERTY_TITLE';
    modalRef.componentInstance.text = 'MODAL_REGISTERED_PROPERTY_TEXT';
    modalRef.componentInstance.icon = 'warning_fill';
    modalRef.componentInstance.type = 'success';
    modalRef.componentInstance.primaryButtonText =
      'MODAL_REGISTERED_PROPERTY_ACTION';

    modalRef.result.then(
      () => {
        if (this.router.url.includes('/my-properties')) {
          location.reload();
        } else {
          this.saved = true;
          this.router.navigate(['/my-properties']);
        }
      },
      () => {
        this.saved = true;
        this.router.navigate(['/my-properties']);
      },
    );
  }

  /**
   * Increases the number of rooms.
   */
  public increase() {
    if (this.propertyForm.controls.rooms.value != null) {
      this.propertyForm.patchValue({
        rooms: parseInt(this.propertyForm.controls.rooms.value) + 1,
      });
    } else {
      this.propertyForm.patchValue({ rooms: 1 });
    }
  }

  /**
   * Decreases the number of rooms (min-value: 0 rooms).
   */
  public decrease() {
    if (parseInt(this.propertyForm.controls.rooms.value) > 0) {
      this.propertyForm.patchValue({
        rooms: parseInt(this.propertyForm.controls.rooms.value) - 1,
      });
    }
  }

  /**
   * Adds attribute to the extra Attribute array if it's not or removes it if it's included.
   * @param {number} item The attribute.
   */
  public pushAttribute(item: number) {
    const attributes = this.propertyForm.controls.extraAttributes.value;
    const index = attributes.indexOf(item);
    if (index !== -1) {
      attributes.splice(index, 1);
    } else {
      attributes.push(item);
    }

    this.propertyForm.patchValue({ extraAttributes: attributes });
  }

  /**
   * Adds element to the contact preferences array if it's not or removes it if it's included.
   * @param {number} item The attribute.
   */
  public pushContactPreference(item: number) {
    const preferences = this.propertyForm.controls.contactPreferences.value;
    const index = preferences.indexOf(item);
    if (index !== -1) {
      preferences.splice(index, 1);
    } else {
      preferences.push(item);
    }

    this.propertyForm.patchValue({ contactPreferences: preferences });
  }

  /**
   * Adds attribute to the extra service array if it's not or removes it if it's included.
   * @param {number} item The attribute.
   */
  public pushService(item: number) {
    const services = this.propertyForm.controls.extraServices.value;
    const index = services.indexOf(item);
    if (index !== -1) {
      services.splice(index, 1);
    } else {
      services.push(item);
    }

    this.propertyForm.patchValue({ extraServices: services });
  }

  /**
   * Updates the selected place on the map.
   */
  private updatePlace(): void {
    const place = this.autocomplete.getPlace();

    if (
      place != null &&
      place.geometry != null &&
      place.geometry.location != null
    ) {
      this.updateLayer(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
      );
      this.center = new LatLng(
        place.geometry.location.lat(),
        place.geometry.location.lng(),
      );
      this.zoom = 13;
      this.propertyForm.patchValue({ address: place.name });

      this.cdRef.detectChanges();
    }
  }

  /**
   * Handles the case in which user adds a phone.
   * @param {ChipsAddEvent} $event The add event.
   */
  public onContactPhoneAdd($event: ChipsAddEvent): void {
    if (!/^\d{10}$/.test($event.value)) {
      this.propertyForm.controls['contactPhones'].value.pop();
    }
  }

  /**
   * Handles the case in which user adds an email.
   * @param {ChipsAddEvent} $event The add event.
   */
  public onContactEmailAdd($event: ChipsAddEvent): void {
    const re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(String($event.value).toLowerCase())) {
      this.propertyForm.controls['contactEmails'].value.pop();
    }
  }

  /**
   * Transforms the link into a trusted one.
   * @param {File} url The url.
   * @returns {SafeResourceUrl} The safe url.
   */
  public transformLink(url: File): SafeResourceUrl {
    // return this.domSanitizer.bypassSecurityTrustResourceUrl(url);
    return this.domSanitizer.bypassSecurityTrustResourceUrl(
      window.URL.createObjectURL(url),
    );
  }

  /**
   * Handles the upload event.
   */
  public uploadHandler(): void {
    this.propertyService
      .editPhotos(
        this.propertyId,
        this.propertyForm.controls.existingPhotoIds.value,
        this.photos,
      )
      .pipe(first())
      .subscribe({
        next: () => {
          location.reload();
        },
        error: () => {},
      });
  }

  /**
   * Handles the file selection event.
   * @param {FileSelectEvent} $event The selection event.
   */
  public filesSelected($event: FileSelectEvent): void {
    this.photos = $event.currentFiles;
  }

  /**
   * Removes the given index from the photos.
   * @param {number} index The index of the removed photo.
   */
  public removeFile(index: number): void {
    this.photos.splice(index, 1);
    this.propertyForm.markAsDirty();
  }

  /**
   * Removes the given index from the existing photos.
   * @param {number} index The index of the removed photo.
   */
  public removeExistingFile(index: number): void {
    this.existingPhotos.splice(index, 1);
    this.propertyForm.patchValue({
      existingPhotoIds: this.existingPhotos.map((photo) => photo.id),
    });
    this.propertyForm.markAsDirty();
  }

  /**
   * Handles the map click event.
   * @param {LeafletMouseEvent} $event The map click event.
   */
  public mapClick($event: LeafletMouseEvent): void {
    this.currentLat = $event.latlng.lat;
    this.currentLng = $event.latlng.lng;
    this.updateLayer($event.latlng.lat, $event.latlng.lng);
    this.reverseGeocode($event.latlng.lat, $event.latlng.lng);
  }

  /**
   * Updates the marker layer.
   * @param {number} lat The latitute of the marker.
   * @param {number} lng The longitude of the marker.
   */
  public updateLayer(lat: number, lng: number): void {
    /* Removed zoom change BRDEV-6.
     */
    // this.zoom = 13;
    this.center = new LatLng(lat, lng);
    if (this.zoom <= 13 && !this.propertyForm.controls.exactLocation.value) {
      this.zoom = 14;
    }

    this.buildMapMarker();

    this.propertyForm.patchValue({ latitude: lat });

    this.propertyForm.patchValue({ longitude: lng });
  }

  /**
   * Reverses the geocode.
   * @param {number} lat The selected latitude.
   * @param {number} lng The selected longitude.
   */
  private reverseGeocode(lat: number, lng: number): void {
    const geocoder = new google.maps.Geocoder();
    const latlng = {
      lat,
      lng,
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    geocoder.geocode({ location: latlng }, (results: any, status: any) => {
      if (status === 'OK') {
        if (results != null && results.length > 0 && results[0] != null) {
          this.propertyForm.patchValue({
            address: results[0].formatted_address,
          });
        }
      }
    });
  }

  /**
   * Event triggered when zoom level is changed
   */
  public buildMapMarker(): void {
    if (this.zoom <= 13 || this.propertyForm.controls.exactLocation.value) {
      this.layers = [
        marker([this.currentLat, this.currentLng], {
          icon: icon({
            iconSize: [25, 41],
            iconAnchor: [13, 41],
            iconUrl: 'assets/marker-icon.png',
            shadowUrl: 'assets/marker-shadow.png',
          }),
        }),
      ];
    } else {
      // Calculate cirlce random offset, in order to avoid
      // address always being in the center of the circle
      let randomLatOffset = Math.random() * (0.001 - 0.0003) + 0.0003;
      if (Math.floor(Math.random() * 2) === 0) {
        randomLatOffset = randomLatOffset - 2 * randomLatOffset;
      }
      let randomLngOffset = Math.random() * (0.0025 - 0.0005) + 0.0005;
      if (Math.floor(Math.random() * 2) === 0) {
        randomLngOffset = randomLngOffset - 2 * randomLngOffset;
      }
      this.layers = [
        circle([this.currentLat, this.currentLng], { radius: 250 }),
      ];
    }
  }

  /**
   * Unpublishes the property.
   */
  public unpublishProperty(): void {
    const modalRef = this.modalService.open(ActionModalComponent, {
      centered: false,
      size: 'xl',
      backdrop: true,
      windowClass: 'action-dialog-modal',
    });

    modalRef.componentInstance.title = 'MODAL_UNPUBLISH_PROPERTY_TITLE';
    modalRef.componentInstance.text = 'MODAL_UNPUBLISH_PROPERTY_TEXT';
    modalRef.componentInstance.icon = 'warning_fill';
    modalRef.componentInstance.type = 'warning';
    modalRef.componentInstance.primaryButtonText =
      'MODAL_UNPUBLISH_PROPERTY_ACTION';
    modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';

    modalRef.result.then(
      () => {
        this.propertyService
          .updatePropertyPublishState(this.propertyId, PublishState.Unpublished)
          .pipe(first())
          .subscribe({
            next: () => {
              location.reload();
            },
            error: () => {},
          });
      },
      () => {},
    );
  }

  /**
   * Publishes the property.
   */
  public publishProperty(): void {
    const modalRef = this.modalService.open(ActionModalComponent, {
      centered: false,
      size: 'xl',
      backdrop: true,
      windowClass: 'action-dialog-modal',
    });

    modalRef.componentInstance.title = 'MODAL_PUBLISH_PROPERTY_TITLE';
    modalRef.componentInstance.text = 'MODAL_PUBLISH_PROPERTY_TEXT';
    modalRef.componentInstance.icon = 'warning_fill';
    modalRef.componentInstance.type = 'default';
    modalRef.componentInstance.primaryButtonText =
      'MODAL_PUBLISH_PROPERTY_ACTION';
    modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';

    modalRef.result.then(
      () => {
        this.propertyService
          .updatePropertyPublishState(this.propertyId, PublishState.Published)
          .pipe(first())
          .subscribe({
            next: () => {
              location.reload();
            },
            error: () => {},
          });
      },
      () => {},
    );
  }

  /**
   * Marks form properties as dirty.
   */
  private makeFormDirty(): void {
    for (const field of Object.keys(this.propertyForm.controls)) {
      this.propertyForm.controls[field].markAsDirty();
    }
  }

  /**
   * Deletes the property.
   */
  public delete(): void {
    const modalRef = this.modalService.open(ActionModalComponent, {
      centered: false,
      size: 'xl',
      backdrop: true,
      windowClass: 'action-dialog-modal',
    });

    modalRef.componentInstance.title = 'MODAL_REMOVE_PROPERTY_TITLE';
    modalRef.componentInstance.text = 'MODAL_REMOVE_PROPERTY_TEXT';
    modalRef.componentInstance.icon = 'alert';
    modalRef.componentInstance.type = 'high-risk';
    modalRef.componentInstance.primaryButtonText =
      'MODAL_REMOVE_PROPERTY_ACTION';
    modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';

    modalRef.result.then(
      () => {
        this.propertyService
          .deleteProperty(this.propertyId)
          .pipe(first())
          .subscribe({
            next: () => {
              this.router.navigate(['/my-properties']);
            },
            error: () => {},
          });
      },
      () => {},
    );
  }

  /**
   * Preselect the availabilityHours.
   */
  public preselectAvailabilityHours(): void {
    if (this.propertyForm.controls.availabilityHours.value == null) {
      this.propertyForm.patchValue({
        availabilityHours: AvailabilityHours.NineToThree,
      });
    }
  }

  /**
   * Emits the 'publish' CTA>
   */
  public publishCta(): void {
    this.$gaService.event(
      'AddProperty-Publish-CTA',
      'Click',
      'AddProperty-CTA',
    );
  }

  /**
   * Emits the 'save' CTA>
   */
  public saveCta(): void {
    this.$gaService.event('AddProperty-Save-CTA', 'Click', 'AddProperty-CTA');
  }

  /**
   * Filters Platforms
   * @param {IPlatform[]} allPlatforms Platforms that are about to be filtered
   * @param {boolean} inGreece True for Greek, false for non-Greek
   * @returns {IPlatform[]} The filtered Platforms
   */
  public filterPlatforms(
    allPlatforms: IPlatform[],
    inGreece: boolean = true,
  ): IPlatform[] {
    return allPlatforms
      .filter((item) => {
        return inGreece
          ? item.website.toString().endsWith('.gr')
          : !item.website.toString().endsWith('.gr');
      })
      .sort((a, b) => {
        return a.id - b.id;
      });
  }
}

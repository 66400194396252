import { Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  UntypedFormGroup,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs/operators';
import { AccountService } from 'src/app/services/account.service';
import { ActionModalComponent } from 'src/app/shared/components/action-modal/action-modal.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  // Public/Private properties.
  public updatePasswordForm: UntypedFormGroup;
  public removeAccountForm: UntypedFormGroup;
  public email: string = '';
  public changePasswordStatus?: boolean;
  public removeAccountStatus?: boolean;
  public passwordChanged?: boolean;
  public error?: string = '';

  constructor(
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
  ) {
    this.updatePasswordForm = this.fb.group(
      {
        currentPassword: ['', Validators.required],
        password: ['', Validators.required],
        confirmPassword: ['', Validators.required],
      },
      { validators: this.matchPasswords() },
    );

    this.removeAccountForm = this.fb.group({
      password: ['', Validators.required],
    });
  }

  /**
   *
   */
  ngOnInit(): void {
    window.scroll(0, 0);

    this.email = this.accountService.accountValue?.email ?? '';
  }

  /**
   * Checks if the passwords do match.
   * @returns {ValidatorFn} The validation's result
   */
  private matchPasswords(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: boolean } | null => {
      const password = control.value.password;
      const confirmPassword = control.value.confirmPassword;

      return password === confirmPassword ? null : { notSame: true };
    };
  }

  /**
   * Cancels the password change and the account
   * removal actions.
   */
  public cancel(): void {
    this.changePasswordStatus = false;
    this.removeAccountStatus = false;
    this.error = undefined;
  }

  /**
   * Changes user's password.
   */
  public changePassword(): void {
    this.accountService
      .changePassword(
        this.updatePasswordForm.value.currentPassword,
        this.updatePasswordForm.value.password,
        this.updatePasswordForm.value.confirmPassword,
      )
      .pipe(first())
      .subscribe({
        next: () => {
          this.passwordChanged = true;
          this.changePasswordStatus = false;
          this.error = undefined;
          this.updatePasswordForm.reset();
        },
        error: (error) => {
          this.error = error.error.message;
        },
      });
  }

  /**
   * Removes the user's account.
   */
  public removeAccount(): void {
    const modalRef = this.modalService.open(ActionModalComponent, {
      centered: false,
      size: 'xl',
      backdrop: true,
      windowClass: 'action-dialog-modal',
    });

    modalRef.componentInstance.title = 'MODAL_REMOVE_PROPERTY_TITLE';
    modalRef.componentInstance.text = 'MODAL_REMOVE_PROPERTY_TEXT';
    modalRef.componentInstance.icon = 'alert';
    modalRef.componentInstance.type = 'high-risk';
    modalRef.componentInstance.primaryButtonText =
      'MODAL_REMOVE_PROPERTY_ACTION';
    modalRef.componentInstance.secondaryButtonText = 'MODAL_CANCEL';

    modalRef.result.then(
      () => {
        this.accountService
          .removeAccount(this.removeAccountForm.value.password)
          .pipe(first())
          .subscribe({
            next: () => {
              this.accountService.logout();
            },
            error: (error) => {
              this.error = error.error.message;
            },
          });
      },
      () => {},
    );
  }
}

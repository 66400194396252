import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { IPricePackage } from '../interfaces/price-package.interface';

@Injectable({
  providedIn: 'root',
})
export class PricePackageService {
  constructor(private http: HttpClient) {}

  /**
   * Fetches every price package.
   * @returns {Observable<IPricePackage[]>} The http response
   */
  public getAll(): Observable<IPricePackage[]> {
    return this.http
      .get<IPricePackage[]>(`${environment.endpoint}/pricepackage/getAll`)
      .pipe(
        map((pricePackages: IPricePackage[]) => {
          return pricePackages;
        }),
      );
  }
}
